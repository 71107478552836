export enum ModalType {
  UserSettingsModal = 'UserSettingsModal',
  AddParticipantModal = 'AddParticipantModal',
  AddParticipantBulkModal = 'AddParticipantBulkModal',
  AddGroupModal = 'AddGroupModal',
  AddRestrictionModal = 'AddRestrictionModal',
  ApprovalSettingsModal = 'ApprovalSettingsModal',
  ViewParticipantModal = 'ViewParticipantModal',
  EditParticipantModal = 'EditParticipantModal',
  ResendUserInviteModal = 'ResendUserInviteModal',
  ViewRestrictionModal = 'ViewRestrictionModal',
  EditRestrictionModal = 'EditRestrictionModal',
  EditApprovalGroupModal = 'EditApprovalGroupModal',
  PendingRequestModal = 'PendingRequestModal',
  TradeRequestViewDetailsModal = 'TradeRequestViewDetailsModal',
  ConfirmationModal = 'ConfirmationModal',
  ViewApprovalGroupModal = 'ViewApprovalGroupModal',
  AddApprovalGroup = 'AddApprovalGroup',
  DeleteModal = 'DeleteModal',
}

import { api } from '../api/api';
import { SignUpRequest } from '../api/backend-api';
import { SET_CURRENT_USER, AUTH_ERROR } from './types';

export const signUp = (user: SignUpRequest) => async (dispatch: any) => {
  try {
    await api.signUp(user);
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  }
};

export const setCurrentUser = (userId: string) => async (dispatch: any) => {
  try {
    const res = await api.getUser(userId);
    dispatch({
      type: SET_CURRENT_USER,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  }
};

import React, { PropsWithChildren } from 'react';
import './DBPageHeader.less';

type headerProps = {
  title: string
};

const DBPageHeader: React.FC<PropsWithChildren<headerProps>> = ({ children, title }) => {
  return <div className="DBPageHeader">
    <div className="DBPageHeaderContent">
      <span className='title'>{title}</span>
      <div>{children}</div>
    </div>
  </div>
}

export default DBPageHeader
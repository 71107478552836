import React from 'react';

import './ModalTextField.less';
interface ModalTextFieldProps {
  title?: string | undefined;
  description?: string | React.ReactElement | React.ReactElement[];
  underline?: boolean;
  renderDivider?: React.ReactElement;
}

const ModalTextField: React.FC<ModalTextFieldProps> = ({
  title,
  description,
  underline,
  renderDivider,
}) => {
  return (
    <div className="ModalTextField">
      <div className="label">{title}</div>
      <div className={`description ${underline ? 'underline' : ''}`}>
        {description}
      </div>
      {renderDivider}
    </div>
  );
};

export default ModalTextField;

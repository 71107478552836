import { format, isBefore, isAfter } from 'date-fns';
import { Restriction, TradeRequest } from '../api/backend-api';

type FilterValues = {
  text: string;
  value: string;
};

interface Filter extends Array<FilterValues> {}

export const formatDate = (date: string | undefined, formated: string) => {
  return date ? format(new Date(Date.parse(date)), formated) : '';
};

export const getInitials = (name: string | undefined) => {
  if (name) {
    const nameArr = name.split(' ');
    return `${nameArr[0][0]} ${nameArr[nameArr.length - 1][0]}`;
  } else {
    return `User`;
  }
};

export const capitalize = (text: string) => {
  return text
    ? text.charAt(0).toUpperCase() + text.toLocaleLowerCase().slice(1)
    : '';
};

export const capitalizeEveryWord = (text: string) => {
  return text
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const capitalizeFilter = (values: Filter) => {
  return values.map((value) => ({ ...value, text: capitalize(value.text) }));
};

export const compareTradeRequests = (a: TradeRequest, b: TradeRequest) => {
  const aStart = Date.parse(a.startDate);
  const bStart = Date.parse(b.startDate);
  if (isBefore(aStart, bStart)) {
    return -1;
  }
  if (isAfter(aStart, bStart)) {
    return 1;
  }

  const aEnd = Date.parse(a.endDate);
  const bEnd = Date.parse(b.endDate);
  if (isBefore(aEnd, bEnd)) {
    return -1;
  }
  if (isAfter(aEnd, bEnd)) {
    return 1;
  }

  return 0;
};

export const compareRestrictions = (a: Restriction, b: Restriction) => {
  const aStart = Date.parse(a.startDate);
  const bStart = Date.parse(b.startDate);
  if (isBefore(aStart, bStart)) {
    return -1;
  }
  if (isAfter(aStart, bStart)) {
    return 1;
  }

  const aEnd = Date.parse(a.endDate);
  const bEnd = Date.parse(b.endDate);
  if (isBefore(aEnd, bEnd)) {
    return -1;
  }
  if (isAfter(aEnd, bEnd)) {
    return 1;
  }

  return 0;
};

export const stringSearch = (q: string, stringToSearch: string) => {
  function escapeRegExp(s: string) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  }
  const words = q
    .split(/\s+/g)
    .map((s) => s.trim())
    .filter((s) => !!s);
  const hasTrailingSpace = q.endsWith(' ');
  const searchRegex = new RegExp(
    words
      .map((word, i) => {
        if (i + 1 === words.length && !hasTrailingSpace) {
          // The last word - ok with the word being "startswith"-like
          return `(?=.*\\b${escapeRegExp(word)})`;
        } else {
          // Not the last word - expect the whole word exactly
          return `(?=.*\\b${escapeRegExp(word)}\\b)`;
        }
      })
      .join('') + '.+',
    'gi',
  );
  return searchRegex.test(stringToSearch);
};

export function getParentNode(element: any, level = 1) {
  while (level-- > 0) {
    element = element.parentNode;
    if (!element) {
      return null;
    }
  }
  return element;
}

import React from 'react';
import './FailedPage.less';

const FailedPage: React.FC = () => {
  return (
    <div className="errorContainer">
      <h2 className="title">Something went wrong.</h2>
      <h3 className="description">
        Something failed during the proccess. Please try again later.
      </h3>
    </div>
  );
};

export default FailedPage;

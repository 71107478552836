import React from 'react';
import DBButton from '../DBButton/DBButton';
import './EmailAuthErrorPage.less';

const EmailAuthErrorPage: React.FC = () => {
  return (
    <div className="EmailAuthErrorPage">
      <img
        className="mobileImage"
        src={process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'}
        alt="Drawbridge Logo"
        style={{ width: 400 }}
      />
      <h3 className="description">
        This link has already been used or is expired.
      </h3>
      <DBButton
        onClick={() => {
          localStorage.removeItem('@drawbridge_apiBearer');
          window.location.replace('/tryforfree');
        }}
        style={{ maxWidth: 180 }}
        variety="create"
      >
        Re-send email
      </DBButton>
    </div>
  );
};

export default EmailAuthErrorPage;

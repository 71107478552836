import { getAllApprovalGroups } from './../actions/approvalGroupActions';
import { getAllGroups } from './../actions/groupActions';
import { getAllUsers } from './../actions/userActions';
import { getAllRestrictions } from './../actions/restrictionsActions';
import { getAllTradeRequests } from '../actions/tradeRequestActions';
import { Tabs as TabsNames } from '../models/Tabs';
const {
  Transactions,
  Participants,
  Groups,
  All,
  Active,
  Future,
  Ended,
  PendingRequests,
  ApprovalGroups,
} = TabsNames;

export const validateRequest = (
  parameter: string,
  dispatch: (e: any) => void,
) => {
  switch (parameter) {
    case Transactions:
      return dispatch(getAllTradeRequests());
    case PendingRequests:
      return dispatch(getAllTradeRequests(undefined, 'PENDING'));
    case Participants:
      return dispatch(getAllUsers());
    case Groups:
      return dispatch(getAllGroups());
    case ApprovalGroups:
      return dispatch(getAllApprovalGroups());
    case All:
      return dispatch(getAllRestrictions());
    case Active:
      return dispatch(getAllRestrictions(undefined, 'ACTIVE'));
    case Future:
      return dispatch(getAllRestrictions(undefined, 'FUTURE'));
    case Ended:
      return dispatch(getAllRestrictions(undefined, 'ENDED'));
    default:
      return null;
  }
};

import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import './Loader.less';

interface LoaderProps extends React.AllHTMLAttributes<HTMLDivElement> {}

export const Loader: React.FC<LoaderProps> = ({ style }) => {
  return (
    <div
      className="loader"
      style={{
        ...style,
        minHeight: style?.minHeight ? style.minHeight : '100vh',
      }}
    >
      <LoadingOutlined style={{ fontSize: 40 }} spin />
    </div>
  );
};

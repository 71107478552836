import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { api } from '../../api/api';
import { TradeRequest, User } from '../../api/backend-api';
import { GeneralState } from '../../models/State';
import { capitalize, formatDate } from '../../utils/Utils';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';
import './TradeRequestViewDetailsModal.less';
import { setModalWidth } from '../../actions/uiActions';
import { useAppDispatch } from '../../hooks/reactRedux';

const TradeRequestViewDetailsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);

  const [tradeRequest, setTradeRequest] = useState<TradeRequest | undefined>();
  const [approvalGroup, setApprovalGroup] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);

  const getTradeRequest = async () => {
    try {
      const tradeRequest: TradeRequest = await api.getTradeRequest(
        currentRow.id,
      );
      setTradeRequest(tradeRequest);
      const user: User[] = await api.getAllUsers(tradeRequest.requesterEmail);
      const approvalGroup = user[0].approvalGroup;
      setApprovalGroup(approvalGroup);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(setModalWidth(800));
    getTradeRequest();

    return () => {
      dispatch(setModalWidth(undefined));
    };
  }, []);

  if (loading) {
    return <></>;
  }

  return (
    <DBModalHeader
      title={`${
        (tradeRequest as any).isExemption ? 'Exemption' : 'Trade'
      } Request for ${tradeRequest?.requesterName}`}
      className="TradeRequestViewDetailsModal"
    >
      <>
        <span>
          Requested{' '}
          <span
            className={tradeRequest?.tradeDirection === 'BUY' ? 'buy' : 'sell'}
          >
            {capitalize(tradeRequest!.tradeDirection.toLowerCase())}
          </span>{' '}
          trade on {formatDate(tradeRequest?.requestedOn, 'h:mm a, d MMM yyyy')}
        </span>
        <Row>
          <Col span={8}>
            <ModalTextField
              title="Trade Status"
              description={`${
                tradeRequest?.status ? capitalize(`${tradeRequest.status}`) : ''
              }`}
            />
          </Col>
          <Col span={8}>
            <ModalTextField
              title="Security"
              description={`${
                tradeRequest?.assetType
                  ? capitalize(`${tradeRequest.assetType}`)
                  : ''
              }`}
            />
          </Col>
          <Col span={8}>
            <ModalTextField
              title="Trade Date"
              description={`${formatDate(
                tradeRequest?.startDate,
                'd MMM yyyy',
              )} - ${formatDate(tradeRequest?.endDate, 'd MMM yyyy')}`}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <ModalTextField
              title="Entity Name"
              description={`${
                tradeRequest?.entityName ? tradeRequest.entityName : ''
              }`}
            />
          </Col>
          <Col span={8}>
            <ModalTextField
              title="Order Type"
              description={`${
                tradeRequest?.tradeType
                  ? capitalize(`${tradeRequest.tradeType}`)
                  : ''
              }`}
            />
          </Col>
          <Col span={8}>
            <ModalTextField
              title="Total Value"
              description={`${
                tradeRequest?.tradeType === 'LIMIT'
                  ? `$${Number(
                      tradeRequest.assetQuantity *
                        (tradeRequest as any).assetUnitValue,
                    ).toFixed(2)}`
                  : ' '
              }`}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <ModalTextField
              title="Number of Shares"
              description={`${
                tradeRequest?.assetQuantity
                  ? tradeRequest.assetQuantity.toLocaleString()
                  : ''
              }`}
            />
          </Col>
          <Col span={8}>
            <ModalTextField
              title="Reason for Trade"
              description={`${
                tradeRequest?.requesterReason
                  ? tradeRequest.requesterReason
                  : ''
              }`}
            />
          </Col>
          <Col span={8}>
            <ModalTextField
              title="Approval Group"
              description={approvalGroup ? `${approvalGroup}` : ''}
            />
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <ModalTextField
              title="Last approver"
              description={`${
                (tradeRequest as any).reviewHistory[0]
                  ? (tradeRequest as any).reviewHistory[0].reviewerName
                  : ''
              }`}
            />
          </Col>
        </Row>
      </>
    </DBModalHeader>
  );
};

export default TradeRequestViewDetailsModal;

import { GET_APPROVAL_GROUPS, APPROVAL_GROUPS_ERROR } from '../actions/types';

const initialState = {
  approvalGroups: [],
  filterValues: null,
  loading: true,
  error: null,
};

export const approvalGroupsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_APPROVAL_GROUPS:
      return {
        ...state,
        approvalGroups: action.payload,
        filterValues: action.filterValues,
        loading: false,
      };
    case APPROVAL_GROUPS_ERROR:
      return {
        approvalGroups: [],
        filterValues: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

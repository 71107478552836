import React from 'react';
import { Layout } from 'antd';
import Sidebar from '../Sidebar/Sidebar';
import DBMainDashboard from './DBMainDashboard';
import { useSelector } from 'react-redux';
import { GeneralState } from '../../models/State';
import { isAfter } from 'date-fns';
import DBButton from '../DBButton/DBButton';
import { Modal } from 'antd';
import { useAuth } from '../../context/auth/authContext';
import { Link } from 'react-router-dom';
import './DashboardWrapper.less';

const DashboardWrapper: React.FC = () => {
  const { logout } = useAuth();
  const { currentUser } = useSelector<GeneralState, any>((state) => state.auth);

  const renderTrialExpiredModal = () => {
    return (
      (<Modal
        className="trialExpiredModal"
        open={true}
        closable={false}
        footer={false}
      >
        <h1 className="title">Your Free Trial has Expired</h1>
        <h3 className="content">
          To continue to use Drawbridge please contact{' '}
          <Link
            to="#"
            onClick={(e) => {
              window.location = 'mailto:support@opendrawbridge.io' as any;
              e.preventDefault();
            }}
          >
            support@opendrawbridge.io
          </Link>
        </h3>
        <div className="buttonsContainer">
          <DBButton variety="create" htmlType="submit" onClick={() => logout()}>
            Go Back
          </DBButton>
        </div>
      </Modal>)
    );
  };

  return (
    <>
      <Layout>
        {currentUser &&
        currentUser.company.trialExpiryDate !== null &&
        isAfter(new Date(), new Date(currentUser.company.trialExpiryDate))
          ? renderTrialExpiredModal()
          : null}
        <Sidebar />
        <DBMainDashboard />
      </Layout>
    </>
  );
};

export default DashboardWrapper;

import { api } from '../api/api';
import { Group } from '../api/backend-api';
import { getFiltersValues } from '../utils/dataUtils';
import { GET_GROUPS, GET_GROUP, GROUPS_ERROR } from './types';

export const getAllGroups = () => async (dispatch: any) => {
  try {
    const res = await api.getAllGroups();
    const filterValues = getFiltersValues(['reviewRequired'], res);

    dispatch({
      type: GET_GROUPS,
      payload: res,
      filterValues,
    });
  } catch (error) {
    dispatch({
      type: GROUPS_ERROR,
      payload: error,
    });
  }
};

export const getGroupByName = (groupName: string) => async (dispatch: any) => {
  try {
    const res = await api.getGroupByName(groupName);
    dispatch({
      type: GET_GROUP,
      payload: res?.data,
    });
  } catch (error) {
    dispatch({
      type: GROUPS_ERROR,
      payload: error,
    });
  }
};

export const addGroup = (group: Group) => async (dispatch: any) => {
  try {
    await api.addGroup(group);
  } catch (error) {
    dispatch({
      type: GROUPS_ERROR,
      payload: error,
    });
  }
};

export const updateGroup =
  (groupId: string, group: Group) => async (dispatch: any) => {
    try {
      await api.updateGroup(groupId, group);
    } catch (error) {
      dispatch({
        type: GROUPS_ERROR,
        payload: error,
      });
    }
  };

export const removeGroup = (groupId: string) => async (dispatch: any) => {
  try {
    await api.removeGroup(groupId);
  } catch (error) {
    dispatch({
      type: GROUPS_ERROR,
      payload: error,
    });
  }
};

import { Tag, TagProps } from 'antd';
import React from 'react';
import { Status } from '../../models/Status';

import './DBTag.less';

export type Theme =
  | 'light'
  | 'approved'
  | 'cancelled'
  | 'future'
  | 'dark'
  | 'grey';

type DBTagProps = {
  iconLeft?: React.ReactNode;
  color?: string;
  isInModal?: boolean;
  /** optional prop that handles request status colouring */
  status?: Status;
  theme?: Theme;
} & TagProps;

const DBTag: React.FC<DBTagProps> = ({
  iconLeft,
  children,
  status,
  theme,
  isInModal = false,
  ...rest
}) => {
  let statusColor: Theme | undefined = undefined;

  //  If status prop is passed, determine statusColor
  statusColor =
    status &&
    (status === Status.Approved ||
    status === Status.Active ||
    status === Status.AutoApproved
      ? 'approved'
      : status === Status.Future
      ? 'future'
      : 'cancelled');

  return (
    <Tag
      className={`DBTag ${theme} ${statusColor} ${
        !isInModal ? 'truncate' : ''
      }`}
      {...rest}
    >
      {iconLeft}
      {children}
    </Tag>
  );
};

export default DBTag;

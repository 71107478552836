import { createCtx } from '../createCtx';

/** the access token returned by Auth0 */
export interface IAccessToken {
  aud: string[];
  azp: string[];
  exp: number;
  'https://api.opendrawbridge.io/v2/': Auth0UserClaimsFromApi;
  iat: number;
  iss: string;
  scope: string;
  sub: string;
}

/** the ID token returned by Auth0 */
export interface IIdToken {
  'https://daml.com/ledger-apiusers_party': string;
  'https://daml.com/ledger-apicompany_party': string;
  at_hash: string;
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  email: string;
  nickname: string;
  nonce: string;
  picture: string;
  sub: string;
  updated_at: string;
}

export interface ICredentials extends Auth0UserClaimsFromApi {
  token: string;

  /** The Auth0 user ID */
  sub: string;
  /** The Auth0 user name */
  name: string;
  /** The Auth0 email */
  email: string;
}

export interface Auth0UserClaimsFromApi {
  companyId: string;
  companyName: string;
  userId: string;
  userEmail: string;
  userRole: 'manager' | 'participant';
}

export interface AuthContextProps {
  login: () => void;
  logout: () => void;
  user?: ICredentials;
  loading: boolean;
}

export const [useAuthContextHook, AuthContextProvider] =
  createCtx<AuthContextProps>();

import React from 'react';
import { Radio } from 'antd';
import './DBRadio.less';

interface DBRadioProps {
  values: string[];
  selectedValue?: string;
  onChange?: (e: any) => void;
  rest?: any;
}

const DBRadio: React.FC<DBRadioProps> = ({
  values,
  onChange,
  selectedValue,
  ...rest
}) => {
  return (
    <Radio.Group
      onChange={onChange}
      value={selectedValue}
      className="DBRadio"
      name="action"
      {...rest}
    >
      {values.map((value) => (
        <Radio.Button value={value} key={value}>
          {value}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default DBRadio;

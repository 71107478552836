import React from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { GeneralState } from '../../models/State';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';
import './ViewApprovalGroupModal.less';
import { formatDate } from '../../utils/Utils';
import { UserLite } from '../../api/backend-api';

interface ViewParticipantModalProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const ViewApprovalGroupModal: React.FC<ViewParticipantModalProps> = ({
  children,
  ...rest
}) => {
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);

  return (
    <DBModalHeader
      title="View Approval Group"
      className="ViewApprovalGroupModal"
      {...rest}
    >
      <ModalTextField title="Name" description={currentRow.name} />
      <ModalTextField
        title="Participants"
        description={`${currentRow.participantCount} participant/s assigned`}
      />
      <ModalTextField
        title="Created On"
        description={`${formatDate(currentRow.createdOn, 'd MMM yyyy')}`}
      />

      <ModalTextField
        title="Managers"
        description={
          <div className="managers-view">
            {currentRow.approvalManagers!?.length > 0
              ? currentRow.approvalManagers.map((manager: UserLite) => (
                  <div key={manager.email} className="manager-info-container">
                    <Avatar
                      style={{
                        backgroundColor: '#353f63',
                        color: '#ffffff',
                      }}
                    >
                      {manager.fullName?.split(' ')[0][0]}
                    </Avatar>
                    <div className="manager-info-description">
                      <div>{manager.fullName}</div>
                      <div>{manager.email}</div>
                    </div>
                  </div>
                ))
              : ''}
          </div>
        }
      />
    </DBModalHeader>
  );
};

export default ViewApprovalGroupModal;

import { useDispatch } from 'react-redux';
import type { AppDispatch } from '../store/index';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();

// This is how we should be able to define the useAppSelector ideally but due to the type mismatch 
// between the types in the modal/state and the type of initialState we cannot use it.
// export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
// The work around would be to use GeneralState instead if RootState 
// export const useAppSelector: TypedUseSelectorHook<GeneralState> = useSelector;

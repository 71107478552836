import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import { Group } from '../../api/backend-api';
import { Form } from 'antd';
import './DBCheckBoxField.less';

const DBCheckBoxField: React.FC<any> = ({
  groups,
  onChange,
  checkedGroups = [],
  ...props
}) => {
  return (
    <Form.Item label="Groups" colon={false}
      labelCol={{ span: 24 }} className="DBCheckBoxField">
      <Checkbox.Group
        className="CheckBoxGroup"
        onChange={onChange}
        defaultValue={checkedGroups}
      >
        <Row gutter={[8, 8]}>
          {groups.map((group: Group) => (
            <Col key={group.id} span={8}>
              <Checkbox value={`${group.name}`} {...props}>
                {group.name}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Form.Item>
  );
};

export default DBCheckBoxField;

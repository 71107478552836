import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { getAllTradeRequests } from '../../actions/tradeRequestActions';
import { TradeRequest, TradeRequestStatusEnum } from '../../api/backend-api';
import DBTableText from '../DBTableText/DBTableText';
import DBTag from '../DBTag/DBTag';
import {
  capitalizeFilter,
  compareTradeRequests,
  formatDate,
  stringSearch,
} from '../../utils/Utils';
import { Status } from '../../models/Status';
import './TransactionsTable.less';
import {
  changeTab,
  setCurrentRow,
  setSearchString,
} from '../../actions/uiActions';
import { GeneralState, UIState } from '../../models/State';
import DBPopover from '../DBPopover/DBPopover';
import { ModalType } from '../../models/Modal';
import { useAppDispatch } from '../../hooks/reactRedux';

const TransactionsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { tradeRequests, loading, filterValues } = useSelector<
    GeneralState,
    any
  >((state) => state.tradeRequests);
  const { searchString } = useSelector<GeneralState, UIState>(
    (state) => state.ui,
  );

  useEffect(() => {
    dispatch(changeTab('Transactions'));
    dispatch(getAllTradeRequests());

    return () => {
      dispatch(setSearchString(''));
    };
  }, [dispatch]);

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${record.requesterName}`}
          subText={`${record.requesterTitle}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          a.requesterName
            .toLowerCase()
            .localeCompare(b.requesterName.toLowerCase()),
      },
    },
    {
      key: 'requestedOn',
      title: 'Request Submitted',
      dataIndex: 'requestedOn',
      ellipsis: true,
      render: (text: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${formatDate(record.requestedOn, 'd MMM yyyy')}`}
          subText={`${formatDate(record.requestedOn, 'h:mm a')}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          Number(new Date(a.requestedOn)) - Number(new Date(b.requestedOn)),
      },
    },
    {
      key: 'details',
      title: 'Details',
      dataIndex: 'details',
      ellipsis: true,
      render: (text: string, record: any) => (
        <DBTableText
          mainText={`${formatDate(
            record.startDate,
            'd MMM yyyy',
          )} - ${formatDate(record.endDate, 'd MMM yyyy')}`}
          subText={`${
            record.isExemption ? 'Exemption Request' : 'Trade Request'
          }`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) => compareTradeRequests(a, b),
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status: string) => (
        <DBTag status={status as Status}>{status}</DBTag>
      ),
      filters: !loading ? filterValues['status'] : [],
      onFilter: (value: any, record: any) => {
        const reg = RegExp(value);
        return reg.exec(record.status)?.index === 0 ? true : false;
      },
    },
    {
      key: 'trade',
      title: 'Trade',
      dataIndex: 'tradeDirection',
      ellipsis: true,
      render: (trade: string) =>
        trade === 'BUY' ? (
          <span className="trade-buy">{trade.toLowerCase()}</span>
        ) : (
          <span className="trade-sell">{trade.toLowerCase()}</span>
        ),
      filters: !loading ? capitalizeFilter(filterValues['tradeDirection']) : [],
      onFilter: (value: any, record: any) =>
        record.tradeDirection.includes(value),
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'assetQuantity',
      ellipsis: true,
      render: (amount: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${record.assetQuantity.toLocaleString()} Shares`}
          subText={`at ${
            record.tradeType === 'LIMIT'
              ? `Limit $${record.assetUnitValue?.toFixed(2)}`
              : 'Market'
          }`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) => a.assetQuantity - b.assetQuantity,
      },
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions',
      width: 50,
      align: 'center',
      render: () => (
        <DBPopover
          options={[
            {
              name: 'View',
              action: ModalType.TradeRequestViewDetailsModal,
            },
          ]}
        />
      ),
    },
  ];

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        dispatch(setCurrentRow(record));
      },
    };
  };

  const tradeRequestsToShow = searchString
    ? tradeRequests.filter((tr: any) =>
        stringSearch(searchString, `${tr.requesterName} ${tr.requesterEmail}`),
      )
    : tradeRequests.filter(
        (tradeRequest: TradeRequest) =>
          tradeRequest.status !== TradeRequestStatusEnum.Pending,
      );

  return (
    <Table
      onRow={handleRow}
      className="TransactionsTable"
      size="small"
      rowKey="id"
      loading={loading}
      pagination={{ showSizeChanger: false }}
      columns={columns as any}
      dataSource={tradeRequestsToShow}
      getPopupContainer={() => {
        return document.getElementsByClassName(
          'TransactionsTable',
        )[0] as HTMLElement;
      }}
    />
  );
};

export default TransactionsTable;

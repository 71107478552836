import React from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { useField, FieldProps } from 'formik';
import { Form } from 'antd';
import 'react-phone-number-input/style.css';
import './DBPhoneInputField.less';

const DBPhoneInputField: React.FC<any> = React.forwardRef(
  ({ name, label, onChange, value, ...rest }, ref) => {
    const [field, meta] = useField<FieldProps>(name);

    return (
      <Form.Item
      colon={false}
      labelCol={{span: 24}}
        label={label}
        help={meta.error && meta.touched ? meta.error : null}
        validateStatus={meta.error && meta.touched ? 'error' : undefined}
      >
        <PhoneInput
          {...rest}
          ref={ref}
          name={name}
          onChange={onChange}
          className="ant-input"
          onBlur={field.onBlur}
        />
      </Form.Item>
    );
  },
);

export default DBPhoneInputField;

import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { Loader } from './components/Loader/Loader';
import { context } from './components/Context/Context';
import DBNotFound from './components/DBNotFound/DBNotFound';
import DashboardWrapper from './components/Dashboard/DashboardWrapper';
import LandingPage from './components/LandingPage/LandingPage';
import DBModal from './components/DBModal/DBModal';
import { useAuth } from './context/auth/authContext';
import EmailVerificationForm from './components/TrialAccountPage/EmailVerificationForm';
import FailedPage from './components/TrialAccountPage/FailedPage';
import DetailsForm from './components/TrialAccountPage/DetailsForm';
import EmailAuthErrorPage from './components/TrialAccountPage/EmailAuthErrorPage';

const AppWrapper: React.FC = () => {
  return (
    <>
      <DBModal />
      <Routes>
        {context.routes.map((path) => (
          <Route key={path} path={path} element={<DashboardWrapper />} />
        ))}
        <Route path="/signup/:emailVerificationCode/:clientAuthCode" element={<EmailAuthErrorPage />} />
        <Route path="/tryforfree" element={<EmailVerificationForm />} />
        <Route path="/failed" element={<FailedPage />} />
        <Route path="/error" element={<EmailAuthErrorPage />} />
        <Route path="*" element={<DBNotFound />} />
      </Routes>
    </>
  );
};

const App: React.FC = () => {
  const { loading, user } = useAuth();

  if (loading) {
    return <Loader />;
  }
  if (user) {
    return <AppWrapper />;
  } else {
    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/tryforfree" element={<EmailVerificationForm />} />
        <Route path="/signup/:emailVerificationCode/:clientAuthCode" element={<DetailsForm />} />
        <Route path="/failed" element={<FailedPage />} />
        <Route path="/error" element={<EmailAuthErrorPage />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    );
  }
};

export default App;

const { useState } = require('react')

/**
 * This allows you to quickly use common state variables
 * e.g.  const { loading, setLoading, err, setErr } = useCommonState();
 */
const useCommonState = (initialLoadingVal?: boolean) => {
  const [loading, setLoading] = useState(initialLoadingVal ?? false)
  const [err, setErr] = useState()
  const [resMsg, setResMsg] = useState()

  const clearAll = () => {
    setLoading(false)
    setErr()
    setResMsg()
  }

  return { loading, setLoading, err, setErr, resMsg, setResMsg, clearAll }
}

export default useCommonState

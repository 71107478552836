import React from 'react';
import { Tabs } from '../../models/Tabs';
import { useLocation } from 'react-router-dom';
import ParticipantsTable from './ParticipantsTable';
import GroupTable from './GroupsTable';
import RestrictionsTable from './RestrictionsTable';
import TransactionsTable from './TransactionsTable';
import PendingRequestsTable from './PendingRequestsTable';
import ApprovalGroupsTable from './ApprovalGroupsTable';

interface DataTableProps {
  tab: string;
}

const DataTable: React.FC<DataTableProps> = ({ tab }) => {
  const pathName = useLocation().pathname;
  const { Transactions, PendingRequests, Groups, ApprovalGroups } = Tabs;

  if (pathName.includes('participants')) {
    return <ParticipantsTable />;
  }

  if (pathName.includes('requests')) {
    switch (tab) {
      case Transactions:
        return <TransactionsTable />;
      case PendingRequests:
        return <PendingRequestsTable />;
      default:
        return null;
    }
  }

  if (pathName.includes('restrictions')) {
    return <RestrictionsTable />;
  }

  if (pathName.includes('groups')) {
    switch (tab) {
      case Groups:
        return <GroupTable />;
      case ApprovalGroups:
        return <ApprovalGroupsTable />;
      default:
        return null;
    }
  }

  return null;
};

export default DataTable;

import React from 'react';
import Calendar, { CalendarProps } from 'react-calendar';
import './DBCalendar.less';
import 'react-calendar/dist/Calendar.css';
import { Form } from 'antd';

type DBCalendarProps = CalendarProps & {
  label?: string;
}

const DBCalendar: React.FC<DBCalendarProps> = ({
  label,
  selectRange,
  activeStartDate,
  value = undefined,
  defaultValue,
  allowPartialRange,
  onChange,
  onClickDay,
}) => {
  const checkIsWeekend = (date: Date) => {
    return date.getDay() === 6 || date.getDay() === 0;
  };

  return (
    <Form.Item label={label} colon={false}
      labelCol={{ span: 24 }}>
      <div className="DBCalendar">
        <Calendar
          value={value}
          tileClassName={({ activeStartDate, date, view }) => {
            return view === 'month' && checkIsWeekend(date) ? 'whiteDay' : null;
          }}
          minDate={new Date()}
          onClickDay={onClickDay}
          activeStartDate={activeStartDate}
          allowPartialRange={allowPartialRange}
          selectRange={selectRange}
          defaultValue={defaultValue}
          next2Label={null}
          prev2Label={null}
          onChange={onChange}
        />
      </div>
    </Form.Item>
  );
};

export default DBCalendar;

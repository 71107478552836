import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Alert, Button, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { setModalWidth } from '../../../actions/uiActions';
import { GridElement, useImportAPI } from '../../../context/CSVContext';
import Box from '../../Box/Box';
import DBButton from '../../DBButton/DBButton';
import Flex from '../../Flex/Flex';
import './TableImport.less';
import { api as restApi } from '../../../api/api';
import useCommonState from '../../../utils/useCommonState';
import DBTag from '../../DBTag/DBTag';
import { useAppDispatch } from '../../../hooks/reactRedux';

class MyReactDataSheet extends ReactDataSheet<GridElement, number> {}

const TableImport: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const setWidthdefaultWidth = () => {
      dispatch(setModalWidth(undefined));
    };
    dispatch(setModalWidth(1000));

    return () => setWidthdefaultWidth();
  }, [dispatch]);

  const {
    grid,
    setGrid,
    addGroupColumn,
    removeGroupColumn,
    canRemoveAddditionalColumns,
    errorCheck,
    setTabIndex,
  } = useImportAPI();

  const errorResults = useMemo(() => {
    return errorCheck();
  }, grid);

  const RowRenderer:
    | ReactDataSheet.RowRenderer<GridElement, any>
    | undefined = ({ children, cells, row}: {children: React.ReactNode, cells: any, row: any}) => {
    // @TODO: this should potentially just be a keyed error lookup
    let hasError = errorResults[row]?.length > 0;
    let hasMultipleErrors = errorResults[row]?.length > 1;

    return (
      <tr>
        <td className="errorCell">
          {hasError && (
            <Tooltip
              title={
                hasMultipleErrors
                  ? 'Row has multiple issues'
                  : errorResults[row][0]?.description
              }
            >
              <WarningOutlined color="blue.300" id={row.toString()} />
            </Tooltip>
          )}
        </td>
        {children}
      </tr>
    );
  };

  const hasErrors = useMemo(
    () =>
      Object.values(errorResults).filter((err) => err.length > 0).length > 0,
    [errorResults],
  );

  const { err: networkErr, setErr: setNetworkErr } = useCommonState();

  const handleUpload = () => {
    setIsLoading(true);
    // Filter empty rows and Convert 2d array to split by commas and newlines
    let csvStringPayload = grid
      // Filter out the first index column
      .map((row, i) => row.filter((cell, i) => i !== 0))
      // Convert the rows to a string split by commas
      .map((row, rowIndex) =>
        row
          .map((cell, i) =>
            rowIndex === 0 && i > 4 ? `Group_${cell.value}` : cell.value,
          )
          .join(','),
      )
      // Filter out any empty rows (note Approval Group can be empty, check for three consecutive commas)
      .filter((row, i) => !row.includes(',,,'))
      // Split the rows by newlines
      .join('\n');

    // Log it
    console.log(csvStringPayload);

    // Upload the payload
    restApi
      .csvUploader(csvStringPayload)
      .then((res) => setTabIndex(0))
      .catch((err) => setNetworkErr('Network error'));

    setIsLoading(false);
  };

  return (
    <Box position="relative">
      <div className="description-container">
        <p>Add up to 100 participants using the table editor below.</p>
        <p>
          Additional groups can be added or removed using the{' '}
          {<MinusCircleOutlined />} and {<PlusCircleOutlined />} buttons located
          above the top right hand side of the table.
        </p>
        <p>
          To indicate if a user should be assigned to a particular group, simply
          enter either a 1 (in group) or 0 (not in group) below the group column
          in the user’s row.
        </p>
      </div>
      <Flex position="absolute" top={-10} right={0}>
        <Tooltip title="Remove a group">
          <Button
            className="removeGroupButton"
            shape="circle"
            aria-label="Remove a group"
            disabled={!canRemoveAddditionalColumns}
            icon={<MinusCircleOutlined />}
            onClick={removeGroupColumn}
          />
        </Tooltip>
        <Tooltip title="Add another group">
          <Button
            className="addGroupButton"
            shape="circle"
            color="none"
            aria-label="Add a group"
            icon={<PlusCircleOutlined />}
            onClick={addGroupColumn}
          />
        </Tooltip>
      </Flex>
      <Box overflow="scroll" maxHeight="300px">
        <Box overflow="hidden">
          <MyReactDataSheet
            data={grid}
            valueRenderer={(cell) => cell.value}
            onCellsChanged={(changes) => {
              let updatedGrid = grid.map((row) => [...row]);
              changes.forEach(({ cell, row, col, value }) => {
                updatedGrid[row][col] = {
                  ...updatedGrid[row][col],
                  value,
                };
              });

              setGrid(updatedGrid);
            }}
            rowRenderer={RowRenderer}
          />
        </Box>
      </Box>
      <Box width="100%">
        <Box marginTop={20}>
          <div
            style={{
              color: '#323f4b',
              marginTop: 12,
              marginBottom: 0,
              fontSize: 18,
            }}
          >
            {networkErr && (
              <DBTag
                theme="cancelled"
                style={{ width: 'min-content', marginLeft: 12 }}
              >
                Something went wrong when uploading your file
              </DBTag>
            )}
          </div>

          {isLoading && (
            <LoadingOutlined
              style={{
                fontSize: 24,
                paddingLeft: 2,
                paddingRight: 4,
              }}
              spin
            />
          )}

          <Box overflow="hidden" margin="1rem 0">
            {hasErrors ? (
              <Alert
                type="error"
                message={
                  <>
                    <h3 style={{ textAlign: 'center', color: '#000' }}>
                      The following errors were found:
                    </h3>
                    {Object.values(errorResults)
                      .filter((err) => err.length > 0)
                      .map(
                        (error, i) =>
                          error && (
                            <div>
                              <div
                                key={i}
                                style={{
                                  color: '#000000',
                                  cursor: 'pointer',
                                  textDecoration: 'underline',
                                }}
                                onClick={() => {
                                  let element = document.getElementById(
                                    error[0].rowId.toString(),
                                  );
                                  element?.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center',
                                  });
                                }}
                              >
                                {error.map((er) => (
                                  <p>{er.description}</p>
                                ))}
                              </div>
                            </div>
                          ),
                      )}
                  </>
                }
              />
            ) : null}
          </Box>
        </Box>
      </Box>
      <div>
        <div className="buttonContainer">
          <DBButton
            variety="create"
            disabled={hasErrors}
            onClick={handleUpload}
          >
            Upload
          </DBButton>
          <DBButton variety="cancel" onClick={() => setTabIndex(0)}>
            Cancel
          </DBButton>
        </div>
      </div>
    </Box>
  );
};

export default TableImport;

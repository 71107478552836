import { notification } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { closeModal } from '../../actions/uiActions';
import { api } from '../../api/api';
import { GeneralState } from '../../models/State';
import DBButton from '../DBButton/DBButton';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';
import './ResendUserInviteModal.less';
import { useAppDispatch } from '../../hooks/reactRedux';

const ResendUserInviteModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);

  const getNameFromCurrentRow = (rowInfo: any) => {
    return rowInfo.name
      ? rowInfo.name
      : `${rowInfo.firstName} ${rowInfo.lastName}`;
  };

  notification.config({
    duration: 5,
  });

  const openNotificationWithIcon = (type: any) => {
    type === 'success'
      ? notification.success({
          message: 'Email successfully sent',
          description: `An email invitation has been sent to ${getNameFromCurrentRow(
            currentRow,
          )}.`,
          style: { color: 'white' },
        })
      : notification.error({
          message: 'Error',
          description:
            'An error occurred while sending the email. Please try again.',
          style: { color: 'white' },
        });
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  const handleResendEmail = async () => {
    try {
      const userId = currentRow.id;
      await api.resendUserInvite(userId);
      openNotificationWithIcon('success');
      dispatch(closeModal());
    } catch (err) {
      console.log(err);
      openNotificationWithIcon('error');
    }
  };

  return (
    <DBModalHeader
      title={'Resend email invitation?'}
      className="ResendUserInviteModal"
    >
      <ModalTextField
        description={
          <span>
            Are you sure you want resend the email invitation to{' '}
            <strong>{getNameFromCurrentRow(currentRow)}</strong>?
          </span>
        }
      />
      <div className="buttonsContainer">
        <DBButton
          variety="create"
          htmlType="submit"
          onClick={handleResendEmail}
        >
          Send
        </DBButton>
        <DBButton variety="cancel" onClick={handleCancel}>
          Cancel
        </DBButton>
      </div>
    </DBModalHeader>
  );
};

export default ResendUserInviteModal;

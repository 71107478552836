import React from 'react';
import './DBTableText.less';

interface DBTableTextProps {
  mainText?: string;
  mainTextClassName?: string;
  subText?: string;
  subtextClassName?: string;
  complementaryText?: string;
  capitalize?: boolean;
}

const DBTableText: React.FC<DBTableTextProps> = ({
  mainText,
  mainTextClassName = '',
  subText,
  subtextClassName = '',
  complementaryText,
  capitalize,
}) => {
  const capitalizeWord = capitalize ? 'capitalize' : '';
  const isExemption =
    subText === 'Exemption Request'
      ? 'exemptionColor'
      : subText === 'Trade Request'
      ? 'tradeColor'
      : '';

  return (
    <>
      {mainText && (
        <>
          <span
            className={`DBTableText maintext ${capitalizeWord} ${mainTextClassName}`}
          >{`${mainText}`}</span>
          <br />
        </>
      )}
      {subText && (
        <>
          <span
            className={`DBTableText subtext ${capitalizeWord} ${subtextClassName} ${isExemption}`}
          >{`${subText}`}</span>
          <br />
        </>
      )}
      {complementaryText && (
        <span
          className={`DBTableText complementaryText ${capitalizeWord}`}
        >{`${complementaryText}`}</span>
      )}
    </>
  );
};

export default DBTableText;

export const context = {
  routes: [
    '/',
    '/overview',
    '/requests',
    '/participants',
    '/restrictions',
    '/groups',
  ],
};

import React, { useState } from 'react';
import { Card, Statistic, StatisticProps } from 'antd';

import './DBStatisticCard.less';
import { TradeRequest, TradeRequestStatusEnum } from '../../api/backend-api';
import { useEffect } from 'react';

interface DBStatisticCardProps {
  title: string;
  tradeRequests: TradeRequest[];
}

const DBStatisticCard: React.FC<DBStatisticCardProps & StatisticProps> = ({
  title,
  tradeRequests,
  ...rest
}) => {
  const [value, setValue] = useState<string | undefined>('-');

  useEffect(() => {
    const shareTradeVolume = () => {
      let sum = 0;

      tradeRequests &&
        tradeRequests
          .filter(
            (tr) =>
              tr.status === TradeRequestStatusEnum.Approved ||
              tr.status === TradeRequestStatusEnum.AutoApproved,
          )
          .forEach((n) => {
            sum += n.assetQuantity;
          });
      // Converts to comma seperated syntax e.g. "8,343"
      return Number(sum).toLocaleString() || '';
    };

    const calculateValues = (title: string, tradeRequests: TradeRequest[]) => {
      switch (title) {
        case 'Pending Approvals':
          return `${
            tradeRequests?.filter(
              (contract) => contract.status === TradeRequestStatusEnum.Pending,
            ).length ?? '-'
          }`;
        case 'Approved Trade Volume':
          return shareTradeVolume();
        case 'Approved Requests':
          return `${
            tradeRequests?.filter(
              (contract) =>
                contract.status === TradeRequestStatusEnum.Approved ||
                contract.status === TradeRequestStatusEnum.AutoApproved,
            ).length ?? '-'
          }`;
        case 'Declined Requests':
          return `${
            tradeRequests?.filter(
              (contract) => contract.status === TradeRequestStatusEnum.Declined,
            ).length ?? '-'
          }`;
        default:
          return undefined;
      }
    };

    setValue(calculateValues(title, tradeRequests));
  }, [title, tradeRequests]);

  return (
    <Card className="DBStatisticCard" bordered={false}>
      <Statistic title={title} value={value} {...rest} />
    </Card>
  );
};

export default DBStatisticCard;

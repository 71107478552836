import { GET_RESTRICTIONS, RESTRICTIONS_ERROR } from './../actions/types';

const initialState = {
  restrictions: [],
  filterValues: null,
  loading: true,
};

export const restrictionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_RESTRICTIONS:
      return {
        ...state,
        restrictions: action.payload,
        filterValues: action.filterValues,
        loading: false,
      };
    case RESTRICTIONS_ERROR:
      return {
        restrictions: [],
        filterValues: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

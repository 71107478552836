/* tslint:disable */
/* eslint-disable */
/**
 * Drawbridge Backend Api Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ApprovalGroup
 */
export interface ApprovalGroup {
    /**
     * 
     * @type {string}
     * @memberof ApprovalGroup
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalGroup
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalGroup
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ApprovalGroup
     */
    'createdOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof ApprovalGroup
     */
    'participantCount'?: number;
    /**
     * 
     * @type {Array<UserLite>}
     * @memberof ApprovalGroup
     */
    'approvalManagers'?: Array<UserLite>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const BulkActionType = {
    Add: 'ADD',
    Remove: 'REMOVE'
} as const;

export type BulkActionType = typeof BulkActionType[keyof typeof BulkActionType];


/**
 * 
 * @export
 * @interface BulkUpdateUserApprovalGroupRequest
 */
export interface BulkUpdateUserApprovalGroupRequest {
    /**
     * 
     * @type {BulkActionType}
     * @memberof BulkUpdateUserApprovalGroupRequest
     */
    'action': BulkActionType;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateUserApprovalGroupRequest
     */
    'users': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateUserApprovalGroupRequest
     */
    'approvalGroup': string;
}
/**
 * 
 * @export
 * @interface BulkUpdateUserGroupsRequest
 */
export interface BulkUpdateUserGroupsRequest {
    /**
     * 
     * @type {BulkActionType}
     * @memberof BulkUpdateUserGroupsRequest
     */
    'action': BulkActionType;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateUserGroupsRequest
     */
    'users': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BulkUpdateUserGroupsRequest
     */
    'groups': Array<string>;
}
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'tradingPolicyUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    'trialExpiryDate'?: string;
}
/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'description'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Group
     */
    'reviewRequired'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    'createdOn'?: string;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    'participantCount'?: number;
}
/**
 * 
 * @export
 * @interface Restriction
 */
export interface Restriction {
    /**
     * 
     * @type {string}
     * @memberof Restriction
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Restriction
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Restriction
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Restriction
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof Restriction
     */
    'endDate': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Restriction
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Restriction
     */
    'status'?: RestrictionStatusEnum;
}

export const RestrictionStatusEnum = {
    Active: 'ACTIVE',
    Future: 'FUTURE',
    Ended: 'ENDED'
} as const;

export type RestrictionStatusEnum = typeof RestrictionStatusEnum[keyof typeof RestrictionStatusEnum];

/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'emailVerificationCode': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'jobTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'companyName': string;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpRequest
     */
    'createDummyData'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'locale'?: string;
}
/**
 * 
 * @export
 * @interface TradeRequest
 */
export interface TradeRequest {
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'requesterEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'requesterName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'requesterTitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'requesterReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'requestedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'entityName': string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'tradeDirection': TradeRequestTradeDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'tradeType': TradeRequestTradeTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'assetType': TradeRequestAssetTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TradeRequest
     */
    'assetQuantity': number;
    /**
     * 
     * @type {number}
     * @memberof TradeRequest
     */
    'assetUnitValue'?: number;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'endDate': string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'status': TradeRequestStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'reviewerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'reviewerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'reviewerComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'reviewedOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TradeRequest
     */
    'tradeSubmitted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TradeRequest
     */
    'tradeSubmittedOn'?: string;
    /**
     * 
     * @type {Array<TradeRequestReviewHistory>}
     * @memberof TradeRequest
     */
    'reviewHistory': Array<TradeRequestReviewHistory>;
    /**
     * 
     * @type {Array<UserLite>}
     * @memberof TradeRequest
     */
    'remainingReviewers': Array<UserLite>;
    /**
     * 
     * @type {UserLite}
     * @memberof TradeRequest
     */
    'additionalReviewer'?: UserLite;
    /**
     * 
     * @type {boolean}
     * @memberof TradeRequest
     */
    'isExemption': boolean;
}

export const TradeRequestTradeDirectionEnum = {
    Buy: 'BUY',
    Sell: 'SELL'
} as const;

export type TradeRequestTradeDirectionEnum = typeof TradeRequestTradeDirectionEnum[keyof typeof TradeRequestTradeDirectionEnum];
export const TradeRequestTradeTypeEnum = {
    Market: 'MARKET',
    Limit: 'LIMIT'
} as const;

export type TradeRequestTradeTypeEnum = typeof TradeRequestTradeTypeEnum[keyof typeof TradeRequestTradeTypeEnum];
export const TradeRequestAssetTypeEnum = {
    Share: 'SHARE',
    Option: 'OPTION'
} as const;

export type TradeRequestAssetTypeEnum = typeof TradeRequestAssetTypeEnum[keyof typeof TradeRequestAssetTypeEnum];
export const TradeRequestStatusEnum = {
    Pending: 'PENDING',
    AutoApproved: 'AUTO-APPROVED',
    Approved: 'APPROVED',
    Declined: 'DECLINED',
    Cancelled: 'CANCELLED'
} as const;

export type TradeRequestStatusEnum = typeof TradeRequestStatusEnum[keyof typeof TradeRequestStatusEnum];

/**
 * 
 * @export
 * @interface TradeRequestReviewHistory
 */
export interface TradeRequestReviewHistory {
    /**
     * 
     * @type {string}
     * @memberof TradeRequestReviewHistory
     */
    'reviewerEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequestReviewHistory
     */
    'reviewerName'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequestReviewHistory
     */
    'reviewedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof TradeRequestReviewHistory
     */
    'status'?: TradeRequestReviewHistoryStatusEnum;
}

export const TradeRequestReviewHistoryStatusEnum = {
    Requested: 'REQUESTED',
    Approved: 'APPROVED',
    Declined: 'DECLINED'
} as const;

export type TradeRequestReviewHistoryStatusEnum = typeof TradeRequestReviewHistoryStatusEnum[keyof typeof TradeRequestReviewHistoryStatusEnum];

/**
 * 
 * @export
 * @interface TradeSubmission
 */
export interface TradeSubmission {
    /**
     * 
     * @type {string}
     * @memberof TradeSubmission
     */
    'phoneNumber'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'jobTitle': string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isManager': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'groups'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'approvalGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastTradeRequestedOn'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastLoggedIn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'isRestricted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'locale'?: string;
    /**
     * 
     * @type {Company}
     * @memberof User
     */
    'company'?: Company;
}
/**
 * 
 * @export
 * @interface UserBulkImportJob
 */
export interface UserBulkImportJob {
    /**
     * 
     * @type {string}
     * @memberof UserBulkImportJob
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserBulkImportJob
     */
    'participantCount'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserBulkImportJob
     */
    'status'?: UserBulkImportJobStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UserBulkImportJob
     */
    'errorReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkImportJob
     */
    'errorCode'?: UserBulkImportJobErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserBulkImportJob
     */
    'errorObject'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserBulkImportJob
     */
    'createdOn'?: string;
}

export const UserBulkImportJobStatusEnum = {
    Pending: 'PENDING',
    InProgress: 'IN_PROGRESS',
    Completed: 'COMPLETED',
    Error: 'ERROR'
} as const;

export type UserBulkImportJobStatusEnum = typeof UserBulkImportJobStatusEnum[keyof typeof UserBulkImportJobStatusEnum];
export const UserBulkImportJobErrorCodeEnum = {
    MissingFieldError: 'MISSING_FIELD_ERROR',
    FieldPatternError: 'FIELD_PATTERN_ERROR',
    EmailNotAvailableError: 'EMAIL_NOT_AVAILABLE_ERROR',
    GroupNotFound: 'GROUP_NOT_FOUND',
    ApprovalGroupNotFound: 'APPROVAL_GROUP_NOT_FOUND',
    InsufficientPrivilege: 'INSUFFICIENT_PRIVILEGE',
    UnknownValidationError: 'UNKNOWN_VALIDATION_ERROR',
    UnknownSystemError: 'UNKNOWN_SYSTEM_ERROR'
} as const;

export type UserBulkImportJobErrorCodeEnum = typeof UserBulkImportJobErrorCodeEnum[keyof typeof UserBulkImportJobErrorCodeEnum];

/**
 * 
 * @export
 * @interface UserLite
 */
export interface UserLite {
    /**
     * 
     * @type {string}
     * @memberof UserLite
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserLite
     */
    'fullName'?: string;
}

/**
 * ApprovalGroupsApi - axios parameter creator
 * @export
 */
export const ApprovalGroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ApprovalGroup} approvalGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApprovalGroup: async (approvalGroup: ApprovalGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'approvalGroup' is not null or undefined
            assertParamExists('addApprovalGroup', 'approvalGroup', approvalGroup)
            const localVarPath = `/v2/approval-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApprovalGroups: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/approval-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} approvalGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeApprovalGroup: async (approvalGroupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'approvalGroupId' is not null or undefined
            assertParamExists('removeApprovalGroup', 'approvalGroupId', approvalGroupId)
            const localVarPath = `/v2/approval-groups/{approvalGroupId}`
                .replace(`{${"approvalGroupId"}}`, encodeURIComponent(String(approvalGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} approvalGroupId 
         * @param {ApprovalGroup} approvalGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApprovalGroup: async (approvalGroupId: string, approvalGroup: ApprovalGroup, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'approvalGroupId' is not null or undefined
            assertParamExists('updateApprovalGroup', 'approvalGroupId', approvalGroupId)
            // verify required parameter 'approvalGroup' is not null or undefined
            assertParamExists('updateApprovalGroup', 'approvalGroup', approvalGroup)
            const localVarPath = `/v2/approval-groups/{approvalGroupId}`
                .replace(`{${"approvalGroupId"}}`, encodeURIComponent(String(approvalGroupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(approvalGroup, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApprovalGroupsApi - functional programming interface
 * @export
 */
export const ApprovalGroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApprovalGroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ApprovalGroup} approvalGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addApprovalGroup(approvalGroup: ApprovalGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApprovalGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addApprovalGroup(approvalGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listApprovalGroups(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ApprovalGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listApprovalGroups(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} approvalGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeApprovalGroup(approvalGroupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeApprovalGroup(approvalGroupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} approvalGroupId 
         * @param {ApprovalGroup} approvalGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateApprovalGroup(approvalGroupId: string, approvalGroup: ApprovalGroup, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateApprovalGroup(approvalGroupId, approvalGroup, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApprovalGroupsApi - factory interface
 * @export
 */
export const ApprovalGroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApprovalGroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {ApprovalGroup} approvalGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addApprovalGroup(approvalGroup: ApprovalGroup, options?: any): AxiosPromise<ApprovalGroup> {
            return localVarFp.addApprovalGroup(approvalGroup, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listApprovalGroups(name?: string, options?: any): AxiosPromise<Array<ApprovalGroup>> {
            return localVarFp.listApprovalGroups(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} approvalGroupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeApprovalGroup(approvalGroupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeApprovalGroup(approvalGroupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} approvalGroupId 
         * @param {ApprovalGroup} approvalGroup 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateApprovalGroup(approvalGroupId: string, approvalGroup: ApprovalGroup, options?: any): AxiosPromise<void> {
            return localVarFp.updateApprovalGroup(approvalGroupId, approvalGroup, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApprovalGroupsApi - object-oriented interface
 * @export
 * @class ApprovalGroupsApi
 * @extends {BaseAPI}
 */
export class ApprovalGroupsApi extends BaseAPI {
    /**
     * 
     * @param {ApprovalGroup} approvalGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalGroupsApi
     */
    public addApprovalGroup(approvalGroup: ApprovalGroup, options?: AxiosRequestConfig) {
        return ApprovalGroupsApiFp(this.configuration).addApprovalGroup(approvalGroup, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalGroupsApi
     */
    public listApprovalGroups(name?: string, options?: AxiosRequestConfig) {
        return ApprovalGroupsApiFp(this.configuration).listApprovalGroups(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} approvalGroupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalGroupsApi
     */
    public removeApprovalGroup(approvalGroupId: string, options?: AxiosRequestConfig) {
        return ApprovalGroupsApiFp(this.configuration).removeApprovalGroup(approvalGroupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} approvalGroupId 
     * @param {ApprovalGroup} approvalGroup 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalGroupsApi
     */
    public updateApprovalGroup(approvalGroupId: string, approvalGroup: ApprovalGroup, options?: AxiosRequestConfig) {
        return ApprovalGroupsApiFp(this.configuration).updateApprovalGroup(approvalGroupId, approvalGroup, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GroupsApi - axios parameter creator
 * @export
 */
export const GroupsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroup: async (group: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'group' is not null or undefined
            assertParamExists('addGroup', 'group', group)
            const localVarPath = `/v2/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (groupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getGroup', 'groupId', groupId)
            const localVarPath = `/v2/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGroup: async (groupId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('removeGroup', 'groupId', groupId)
            const localVarPath = `/v2/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (groupId: string, group: Group, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateGroup', 'groupId', groupId)
            // verify required parameter 'group' is not null or undefined
            assertParamExists('updateGroup', 'group', group)
            const localVarPath = `/v2/groups/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(group, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsApi - functional programming interface
 * @export
 */
export const GroupsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addGroup(group: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addGroup(group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(groupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGroups(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Group>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGroups(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeGroup(groupId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeGroup(groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(groupId: string, group: Group, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(groupId, group, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsApi - factory interface
 * @export
 */
export const GroupsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsApiFp(configuration)
    return {
        /**
         * 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addGroup(group: Group, options?: any): AxiosPromise<Group> {
            return localVarFp.addGroup(group, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(groupId: string, options?: any): AxiosPromise<Group> {
            return localVarFp.getGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(name?: string, options?: any): AxiosPromise<Array<Group>> {
            return localVarFp.listGroups(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeGroup(groupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} groupId 
         * @param {Group} group 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(groupId: string, group: Group, options?: any): AxiosPromise<void> {
            return localVarFp.updateGroup(groupId, group, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsApi - object-oriented interface
 * @export
 * @class GroupsApi
 * @extends {BaseAPI}
 */
export class GroupsApi extends BaseAPI {
    /**
     * 
     * @param {Group} group 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public addGroup(group: Group, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).addGroup(group, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public getGroup(groupId: string, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).getGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public listGroups(name?: string, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).listGroups(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public removeGroup(groupId: string, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).removeGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} groupId 
     * @param {Group} group 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsApi
     */
    public updateGroup(groupId: string, group: Group, options?: AxiosRequestConfig) {
        return GroupsApiFp(this.configuration).updateGroup(groupId, group, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalCompaniesApi - axios parameter creator
 * @export
 */
export const InternalCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany: async (companyId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompany', 'companyId', companyId)
            const localVarPath = `/internal/companies/{companyId}`
                .replace(`{${"companyId"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalCompaniesApi - functional programming interface
 * @export
 */
export const InternalCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompany(companyId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalCompaniesApi - factory interface
 * @export
 */
export const InternalCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} companyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompany(companyId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteCompany(companyId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalCompaniesApi - object-oriented interface
 * @export
 * @class InternalCompaniesApi
 * @extends {BaseAPI}
 */
export class InternalCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {string} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalCompaniesApi
     */
    public deleteCompany(companyId: string, options?: AxiosRequestConfig) {
        return InternalCompaniesApiFp(this.configuration).deleteCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InternalJobsApi - axios parameter creator
 * @export
 */
export const InternalJobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOutboxMessages: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/jobs/publish-outbox-messages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendParticipantLoginReminderEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/jobs/send-participant-login-reminder-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRestrictionEndingEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/jobs/send-restriction-ending-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSummaryEmails: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/jobs/send-summary-emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAuth0UsersLastLoggedIn: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/internal/jobs/sync-auth0-users-last-logged-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalJobsApi - functional programming interface
 * @export
 */
export const InternalJobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalJobsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishOutboxMessages(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishOutboxMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendParticipantLoginReminderEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendParticipantLoginReminderEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendRestrictionEndingEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendRestrictionEndingEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendSummaryEmails(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendSummaryEmails(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAuth0UsersLastLoggedIn(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAuth0UsersLastLoggedIn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InternalJobsApi - factory interface
 * @export
 */
export const InternalJobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalJobsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishOutboxMessages(options?: any): AxiosPromise<void> {
            return localVarFp.publishOutboxMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendParticipantLoginReminderEmails(options?: any): AxiosPromise<void> {
            return localVarFp.sendParticipantLoginReminderEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendRestrictionEndingEmails(options?: any): AxiosPromise<void> {
            return localVarFp.sendRestrictionEndingEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendSummaryEmails(options?: any): AxiosPromise<void> {
            return localVarFp.sendSummaryEmails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAuth0UsersLastLoggedIn(options?: any): AxiosPromise<void> {
            return localVarFp.syncAuth0UsersLastLoggedIn(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalJobsApi - object-oriented interface
 * @export
 * @class InternalJobsApi
 * @extends {BaseAPI}
 */
export class InternalJobsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalJobsApi
     */
    public publishOutboxMessages(options?: AxiosRequestConfig) {
        return InternalJobsApiFp(this.configuration).publishOutboxMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalJobsApi
     */
    public sendParticipantLoginReminderEmails(options?: AxiosRequestConfig) {
        return InternalJobsApiFp(this.configuration).sendParticipantLoginReminderEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalJobsApi
     */
    public sendRestrictionEndingEmails(options?: AxiosRequestConfig) {
        return InternalJobsApiFp(this.configuration).sendRestrictionEndingEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalJobsApi
     */
    public sendSummaryEmails(options?: AxiosRequestConfig) {
        return InternalJobsApiFp(this.configuration).sendSummaryEmails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalJobsApi
     */
    public syncAuth0UsersLastLoggedIn(options?: AxiosRequestConfig) {
        return InternalJobsApiFp(this.configuration).syncAuth0UsersLastLoggedIn(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicUsersApi - axios parameter creator
 * @export
 */
export const PublicUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailAvailable: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('isEmailAvailable', 'email', email)
            const localVarPath = `/v2/public/users/{email}/is-email-available`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {string} clientAuthCode 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationEmail: async (email: string, clientAuthCode: string, locale?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('sendVerificationEmail', 'email', email)
            // verify required parameter 'clientAuthCode' is not null or undefined
            assertParamExists('sendVerificationEmail', 'clientAuthCode', clientAuthCode)
            const localVarPath = `/v2/public/users/{email}/send-verification-email`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientAuthCode !== undefined) {
                localVarQueryParameter['clientAuthCode'] = clientAuthCode;
            }

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (signUpRequest: SignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('signUp', 'signUpRequest', signUpRequest)
            const localVarPath = `/v2/public/users/signup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicUsersApi - functional programming interface
 * @export
 */
export const PublicUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async isEmailAvailable(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.isEmailAvailable(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} email 
         * @param {string} clientAuthCode 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendVerificationEmail(email: string, clientAuthCode: string, locale?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendVerificationEmail(email, clientAuthCode, locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(signUpRequest: SignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(signUpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicUsersApi - factory interface
 * @export
 */
export const PublicUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicUsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isEmailAvailable(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.isEmailAvailable(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} email 
         * @param {string} clientAuthCode 
         * @param {string} [locale] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendVerificationEmail(email: string, clientAuthCode: string, locale?: string, options?: any): AxiosPromise<void> {
            return localVarFp.sendVerificationEmail(email, clientAuthCode, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpRequest: SignUpRequest, options?: any): AxiosPromise<string> {
            return localVarFp.signUp(signUpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicUsersApi - object-oriented interface
 * @export
 * @class PublicUsersApi
 * @extends {BaseAPI}
 */
export class PublicUsersApi extends BaseAPI {
    /**
     * 
     * @param {string} email 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicUsersApi
     */
    public isEmailAvailable(email: string, options?: AxiosRequestConfig) {
        return PublicUsersApiFp(this.configuration).isEmailAvailable(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} email 
     * @param {string} clientAuthCode 
     * @param {string} [locale] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicUsersApi
     */
    public sendVerificationEmail(email: string, clientAuthCode: string, locale?: string, options?: AxiosRequestConfig) {
        return PublicUsersApiFp(this.configuration).sendVerificationEmail(email, clientAuthCode, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignUpRequest} signUpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicUsersApi
     */
    public signUp(signUpRequest: SignUpRequest, options?: AxiosRequestConfig) {
        return PublicUsersApiFp(this.configuration).signUp(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RestrictionsApi - axios parameter creator
 * @export
 */
export const RestrictionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Restriction} restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRestriction: async (restriction: Restriction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restriction' is not null or undefined
            assertParamExists('addRestriction', 'restriction', restriction)
            const localVarPath = `/v2/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restriction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestriction: async (restrictionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('getRestriction', 'restrictionId', restrictionId)
            const localVarPath = `/v2/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'FUTURE' | 'ENDED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRestrictions: async (email?: string, status?: 'ACTIVE' | 'FUTURE' | 'ENDED', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/restrictions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRestriction: async (restrictionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('removeRestriction', 'restrictionId', restrictionId)
            const localVarPath = `/v2/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {Restriction} restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestriction: async (restrictionId: string, restriction: Restriction, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'restrictionId' is not null or undefined
            assertParamExists('updateRestriction', 'restrictionId', restrictionId)
            // verify required parameter 'restriction' is not null or undefined
            assertParamExists('updateRestriction', 'restriction', restriction)
            const localVarPath = `/v2/restrictions/{restrictionId}`
                .replace(`{${"restrictionId"}}`, encodeURIComponent(String(restrictionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restriction, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RestrictionsApi - functional programming interface
 * @export
 */
export const RestrictionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RestrictionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Restriction} restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addRestriction(restriction: Restriction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Restriction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addRestriction(restriction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRestriction(restrictionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Restriction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRestriction(restrictionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'FUTURE' | 'ENDED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRestrictions(email?: string, status?: 'ACTIVE' | 'FUTURE' | 'ENDED', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Restriction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRestrictions(email, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeRestriction(restrictionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeRestriction(restrictionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {Restriction} restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateRestriction(restrictionId: string, restriction: Restriction, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateRestriction(restrictionId, restriction, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RestrictionsApi - factory interface
 * @export
 */
export const RestrictionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RestrictionsApiFp(configuration)
    return {
        /**
         * 
         * @param {Restriction} restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRestriction(restriction: Restriction, options?: any): AxiosPromise<Restriction> {
            return localVarFp.addRestriction(restriction, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRestriction(restrictionId: string, options?: any): AxiosPromise<Restriction> {
            return localVarFp.getRestriction(restrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {'ACTIVE' | 'FUTURE' | 'ENDED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRestrictions(email?: string, status?: 'ACTIVE' | 'FUTURE' | 'ENDED', options?: any): AxiosPromise<Array<Restriction>> {
            return localVarFp.listRestrictions(email, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeRestriction(restrictionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeRestriction(restrictionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} restrictionId 
         * @param {Restriction} restriction 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRestriction(restrictionId: string, restriction: Restriction, options?: any): AxiosPromise<void> {
            return localVarFp.updateRestriction(restrictionId, restriction, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RestrictionsApi - object-oriented interface
 * @export
 * @class RestrictionsApi
 * @extends {BaseAPI}
 */
export class RestrictionsApi extends BaseAPI {
    /**
     * 
     * @param {Restriction} restriction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionsApi
     */
    public addRestriction(restriction: Restriction, options?: AxiosRequestConfig) {
        return RestrictionsApiFp(this.configuration).addRestriction(restriction, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} restrictionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionsApi
     */
    public getRestriction(restrictionId: string, options?: AxiosRequestConfig) {
        return RestrictionsApiFp(this.configuration).getRestriction(restrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {'ACTIVE' | 'FUTURE' | 'ENDED'} [status] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionsApi
     */
    public listRestrictions(email?: string, status?: 'ACTIVE' | 'FUTURE' | 'ENDED', options?: AxiosRequestConfig) {
        return RestrictionsApiFp(this.configuration).listRestrictions(email, status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} restrictionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionsApi
     */
    public removeRestriction(restrictionId: string, options?: AxiosRequestConfig) {
        return RestrictionsApiFp(this.configuration).removeRestriction(restrictionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} restrictionId 
     * @param {Restriction} restriction 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RestrictionsApi
     */
    public updateRestriction(restrictionId: string, restriction: Restriction, options?: AxiosRequestConfig) {
        return RestrictionsApiFp(this.configuration).updateRestriction(restrictionId, restriction, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TradeRequestsApi - axios parameter creator
 * @export
 */
export const TradeRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTradeRequest: async (tradeRequest: TradeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeRequest' is not null or undefined
            assertParamExists('addTradeRequest', 'tradeRequest', tradeRequest)
            const localVarPath = `/v2/trade-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeRequest: async (tradeRequestId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeRequestId' is not null or undefined
            assertParamExists('getTradeRequest', 'tradeRequestId', tradeRequestId)
            const localVarPath = `/v2/trade-requests/{tradeRequestId}`
                .replace(`{${"tradeRequestId"}}`, encodeURIComponent(String(tradeRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED'} [status] 
         * @param {boolean} [isActive] 
         * @param {string} [requestedOnFrom] 
         * @param {string} [requestedOnTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTradeRequests: async (email?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED', isActive?: boolean, requestedOnFrom?: string, requestedOnTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/trade-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            if (requestedOnFrom !== undefined) {
                localVarQueryParameter['requestedOnFrom'] = requestedOnFrom;
            }

            if (requestedOnTo !== undefined) {
                localVarQueryParameter['requestedOnTo'] = requestedOnTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {TradeSubmission} tradeSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTradeRequestForTrade: async (tradeRequestId: string, tradeSubmission: TradeSubmission, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeRequestId' is not null or undefined
            assertParamExists('submitTradeRequestForTrade', 'tradeRequestId', tradeRequestId)
            // verify required parameter 'tradeSubmission' is not null or undefined
            assertParamExists('submitTradeRequestForTrade', 'tradeSubmission', tradeSubmission)
            const localVarPath = `/v2/trade-requests/{tradeRequestId}/submit-for-trade`
                .replace(`{${"tradeRequestId"}}`, encodeURIComponent(String(tradeRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeSubmission, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeRequest: async (tradeRequestId: string, tradeRequest: TradeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tradeRequestId' is not null or undefined
            assertParamExists('updateTradeRequest', 'tradeRequestId', tradeRequestId)
            // verify required parameter 'tradeRequest' is not null or undefined
            assertParamExists('updateTradeRequest', 'tradeRequest', tradeRequest)
            const localVarPath = `/v2/trade-requests/{tradeRequestId}`
                .replace(`{${"tradeRequestId"}}`, encodeURIComponent(String(tradeRequestId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tradeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TradeRequestsApi - functional programming interface
 * @export
 */
export const TradeRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TradeRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addTradeRequest(tradeRequest: TradeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addTradeRequest(tradeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTradeRequest(tradeRequestId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TradeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTradeRequest(tradeRequestId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED'} [status] 
         * @param {boolean} [isActive] 
         * @param {string} [requestedOnFrom] 
         * @param {string} [requestedOnTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTradeRequests(email?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED', isActive?: boolean, requestedOnFrom?: string, requestedOnTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TradeRequest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTradeRequests(email, status, isActive, requestedOnFrom, requestedOnTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {TradeSubmission} tradeSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitTradeRequestForTrade(tradeRequestId: string, tradeSubmission: TradeSubmission, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submitTradeRequestForTrade(tradeRequestId, tradeSubmission, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTradeRequest(tradeRequestId: string, tradeRequest: TradeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTradeRequest(tradeRequestId, tradeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TradeRequestsApi - factory interface
 * @export
 */
export const TradeRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TradeRequestsApiFp(configuration)
    return {
        /**
         * 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addTradeRequest(tradeRequest: TradeRequest, options?: any): AxiosPromise<TradeRequest> {
            return localVarFp.addTradeRequest(tradeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTradeRequest(tradeRequestId: string, options?: any): AxiosPromise<TradeRequest> {
            return localVarFp.getTradeRequest(tradeRequestId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED'} [status] 
         * @param {boolean} [isActive] 
         * @param {string} [requestedOnFrom] 
         * @param {string} [requestedOnTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTradeRequests(email?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED', isActive?: boolean, requestedOnFrom?: string, requestedOnTo?: string, options?: any): AxiosPromise<Array<TradeRequest>> {
            return localVarFp.listTradeRequests(email, status, isActive, requestedOnFrom, requestedOnTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {TradeSubmission} tradeSubmission 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitTradeRequestForTrade(tradeRequestId: string, tradeSubmission: TradeSubmission, options?: any): AxiosPromise<void> {
            return localVarFp.submitTradeRequestForTrade(tradeRequestId, tradeSubmission, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tradeRequestId 
         * @param {TradeRequest} tradeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTradeRequest(tradeRequestId: string, tradeRequest: TradeRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateTradeRequest(tradeRequestId, tradeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TradeRequestsApi - object-oriented interface
 * @export
 * @class TradeRequestsApi
 * @extends {BaseAPI}
 */
export class TradeRequestsApi extends BaseAPI {
    /**
     * 
     * @param {TradeRequest} tradeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeRequestsApi
     */
    public addTradeRequest(tradeRequest: TradeRequest, options?: AxiosRequestConfig) {
        return TradeRequestsApiFp(this.configuration).addTradeRequest(tradeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tradeRequestId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeRequestsApi
     */
    public getTradeRequest(tradeRequestId: string, options?: AxiosRequestConfig) {
        return TradeRequestsApiFp(this.configuration).getTradeRequest(tradeRequestId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED'} [status] 
     * @param {boolean} [isActive] 
     * @param {string} [requestedOnFrom] 
     * @param {string} [requestedOnTo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeRequestsApi
     */
    public listTradeRequests(email?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED', isActive?: boolean, requestedOnFrom?: string, requestedOnTo?: string, options?: AxiosRequestConfig) {
        return TradeRequestsApiFp(this.configuration).listTradeRequests(email, status, isActive, requestedOnFrom, requestedOnTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tradeRequestId 
     * @param {TradeSubmission} tradeSubmission 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeRequestsApi
     */
    public submitTradeRequestForTrade(tradeRequestId: string, tradeSubmission: TradeSubmission, options?: AxiosRequestConfig) {
        return TradeRequestsApiFp(this.configuration).submitTradeRequestForTrade(tradeRequestId, tradeSubmission, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tradeRequestId 
     * @param {TradeRequest} tradeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TradeRequestsApi
     */
    public updateTradeRequest(tradeRequestId: string, tradeRequest: TradeRequest, options?: AxiosRequestConfig) {
        return TradeRequestsApiFp(this.configuration).updateTradeRequest(tradeRequestId, tradeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('addUser', 'user', user)
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkImportUsers: async (body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/bulk-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/csv';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkUpdateUserApprovalGroupRequest} bulkUpdateUserApprovalGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserApprovalGroup: async (bulkUpdateUserApprovalGroupRequest: BulkUpdateUserApprovalGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpdateUserApprovalGroupRequest' is not null or undefined
            assertParamExists('bulkUpdateUserApprovalGroup', 'bulkUpdateUserApprovalGroupRequest', bulkUpdateUserApprovalGroupRequest)
            const localVarPath = `/v2/users/bulk-update-approval-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateUserApprovalGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BulkUpdateUserGroupsRequest} bulkUpdateUserGroupsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserGroups: async (bulkUpdateUserGroupsRequest: BulkUpdateUserGroupsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bulkUpdateUserGroupsRequest' is not null or undefined
            assertParamExists('bulkUpdateUserGroups', 'bulkUpdateUserGroupsRequest', bulkUpdateUserGroupsRequest)
            const localVarPath = `/v2/users/bulk-update-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bulkUpdateUserGroupsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUser', 'userId', userId)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserBulkImportJobs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users/bulk-import`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v2/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('removeUser', 'userId', userId)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInvite: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('resendUserInvite', 'userId', userId)
            const localVarPath = `/v2/users/{userId}/resend-invite`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (userId: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUser', 'userId', userId)
            // verify required parameter 'user' is not null or undefined
            assertParamExists('updateUser', 'user', user)
            const localVarPath = `/v2/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkImportUsers(body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkImportUsers(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkUpdateUserApprovalGroupRequest} bulkUpdateUserApprovalGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateUserApprovalGroup(bulkUpdateUserApprovalGroupRequest: BulkUpdateUserApprovalGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateUserApprovalGroup(bulkUpdateUserApprovalGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BulkUpdateUserGroupsRequest} bulkUpdateUserGroupsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bulkUpdateUserGroups(bulkUpdateUserGroupsRequest: BulkUpdateUserGroupsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bulkUpdateUserGroups(bulkUpdateUserGroupsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUserBulkImportJobs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserBulkImportJob>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUserBulkImportJobs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsers(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendUserInvite(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendUserInvite(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(userId: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(userId, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(user: User, options?: any): AxiosPromise<User> {
            return localVarFp.addUser(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkImportUsers(body?: string, options?: any): AxiosPromise<void> {
            return localVarFp.bulkImportUsers(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkUpdateUserApprovalGroupRequest} bulkUpdateUserApprovalGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserApprovalGroup(bulkUpdateUserApprovalGroupRequest: BulkUpdateUserApprovalGroupRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkUpdateUserApprovalGroup(bulkUpdateUserApprovalGroupRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BulkUpdateUserGroupsRequest} bulkUpdateUserGroupsRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bulkUpdateUserGroups(bulkUpdateUserGroupsRequest: BulkUpdateUserGroupsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.bulkUpdateUserGroups(bulkUpdateUserGroupsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(userId: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUserBulkImportJobs(options?: any): AxiosPromise<Array<UserBulkImportJob>> {
            return localVarFp.listUserBulkImportJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsers(email?: string, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.listUsers(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendUserInvite(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resendUserInvite(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {User} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(userId: string, user: User, options?: any): AxiosPromise<void> {
            return localVarFp.updateUser(userId, user, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addUser(user: User, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).addUser(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public bulkImportUsers(body?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).bulkImportUsers(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkUpdateUserApprovalGroupRequest} bulkUpdateUserApprovalGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public bulkUpdateUserApprovalGroup(bulkUpdateUserApprovalGroupRequest: BulkUpdateUserApprovalGroupRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).bulkUpdateUserApprovalGroup(bulkUpdateUserApprovalGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BulkUpdateUserGroupsRequest} bulkUpdateUserGroupsRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public bulkUpdateUserGroups(bulkUpdateUserGroupsRequest: BulkUpdateUserGroupsRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).bulkUpdateUserGroups(bulkUpdateUserGroupsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUserBulkImportJobs(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUserBulkImportJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsers(email?: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUsers(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeUser(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).removeUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resendUserInvite(userId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).resendUserInvite(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {User} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(userId: string, user: User, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(userId, user, options).then((request) => request(this.axios, this.basePath));
    }
}



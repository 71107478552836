import { GET_USERS, USERS_ERROR } from './../actions/types';

const initialState = {
  users: [],
  filterValues: null,
  loading: true,
};

export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        filterValues: action.filterValues,
        loading: false,
      };
    case USERS_ERROR:
      return {
        users: [],
        filterValues: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

import React from 'react';
import './VerificationEmailMessage.less';

interface VerificationEmailMessageProps {
  setIsSubmitted: (e: any) => void;
}

const VerificationEmailMessage: React.FC<VerificationEmailMessageProps> = ({
  setIsSubmitted,
}) => {
  return (
    <div className="VerificationEmailMessage">
      <h1>Almost there!</h1>
      <h1>We just need to verify your email to get started</h1>
      <div className="description">
        <p>
          Please click on the link in your email to verify your email address.
        </p>

        <p>
          Didn't receive an email from us? {' '}
          <span className="link" onClick={() => setIsSubmitted(false)}>
            Re-send email.
          </span>
        </p>

        <p>Want to get in touch? Email support@opendrawbridge.io.</p>
      </div>
    </div>
  );
};

export default VerificationEmailMessage;

import React from 'react';
import { Badge } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';

interface DBBadgeProps {
  size?: 'default' | 'small' | undefined;
  className?: string;
  onCountChange: () => number;
}

const DBBadge: React.FC<DBBadgeProps> = ({
  size = 'default',
  className,
  onCountChange,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(onCountChange());
  }, [setCount, onCountChange]);

  return <Badge size={size} className={className} count={count} />;
};

export default DBBadge;

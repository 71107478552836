export enum Tabs {
  Participants = 'Participants',
  Groups = 'Groups',
  Transactions = 'Transactions',
  PendingRequests = 'Pending Requests',
  ApprovalGroups = 'Approval Groups',
  Restrictions = 'Restrictions',
  All = 'All',
  Active = 'Active',
  Future = 'Future',
  Ended = 'Ended',
}

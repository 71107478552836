import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { TradeRequest } from '../../api/backend-api';
import DBTableText from '../DBTableText/DBTableText';
import { formatDate, stringSearch } from '../../utils/Utils';
import {
  changeTab,
  openModal,
  setCurrentRow,
  setSearchString,
} from '../../actions/uiActions';
import DBButton from '../DBButton/DBButton';
import './PendingRequestsTable.less';
import { ModalType } from '../../models/Modal';
import { GeneralState, UIState } from '../../models/State';
import { useAppDispatch } from '../../hooks/reactRedux';

interface State {
  tradeRequests: TradeRequest[];
  loading: boolean;
}

const PendingRequestsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { tradeRequests, loading } = useSelector<State, any>(
    (state) => state.tradeRequests,
  );
  const { searchString } = useSelector<GeneralState, UIState>(
    (state) => state.ui,
  );

  useEffect(() => {
    dispatch(changeTab('Pending Requests'));

    return () => {
      dispatch(setSearchString(''));
    };
  }, [dispatch]);

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${record.requesterName}`}
          subText={`${record.requesterTitle}`}
        />
      ),
    },
    {
      key: 'requestedOn',
      title: 'Request Submitted',
      dataIndex: 'requestedOn',
      ellipsis: true,
      render: (text: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${formatDate(record.requestedOn, 'd MMM yyyy')}`}
          subText={`${formatDate(record.requestedOn, 'h:mm a')}`}
        />
      ),
    },
    {
      key: 'details',
      title: 'Details',
      dataIndex: 'details',
      ellipsis: true,
      render: (text: string, record: any) => (
        <DBTableText
          mainText={`${formatDate(
            record.startDate,
            'd MMM yyyy',
          )} - ${formatDate(record.endDate, 'd MMM yyyy')}`}
          subText={record.isExemption ? 'Exemption Request' : 'Trade Request'}
        />
      ),
    },
    {
      key: 'trade',
      title: 'Trade',
      dataIndex: 'tradeDirection',
      ellipsis: true,
      render: (trade: string) =>
        trade === 'BUY' ? (
          <span className="trade-buy">{trade.toLowerCase()}</span>
        ) : (
          <span className="trade-sell">{trade.toLowerCase()}</span>
        ),
    },
    {
      key: 'quantity',
      title: 'Quantity',
      dataIndex: 'assetQuantity',
      ellipsis: true,
      render: (amount: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${record.assetQuantity.toLocaleString()} Shares`}
          subText={`at ${
            record.tradeType === 'LIMIT'
              ? `Limit $${record.assetUnitValue?.toFixed(2)}`
              : 'Market'
          }`}
        />
      ),
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions',
      className: 'actionButtons',
      render: (action: string, record: any) => (
        <DBButton
          className="reviewButton"
          onClick={() =>
            dispatch(openModal(ModalType.PendingRequestModal, record))
          }
        >
          Review
        </DBButton>
      ),
    },
  ];

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        dispatch(setCurrentRow(record));
      },
    };
  };

  const tradeRequestsToShow = searchString
    ? tradeRequests.filter((tr: TradeRequest) =>
        stringSearch(searchString, `${tr.requesterName} ${tr.requesterEmail}`),
      )
    : tradeRequests;

  return (
    <Table
      className="PendingRequestsTable"
      onRow={handleRow}
      size="small"
      rowKey="id"
      loading={loading}
      pagination={{ showSizeChanger: false }}
      columns={columns as any}
      dataSource={tradeRequestsToShow}
    />
  );
};

export default PendingRequestsTable;

import { SET_CURRENT_USER } from './../actions/types';

const initialState = {
  currentUser: null,
  loading: true,
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

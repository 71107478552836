import { api } from '../api/api';
import {
  ACTIVE_TRADES_ERROR,
  ACTIVE_TRADES_PER_MONTH_ERROR,
  GET_ACTIVE_TRADES,
  GET_ACTIVE_TRADES_PER_MONTH,
} from './types';

export const getActiveTrades = () => async (dispatch: any) => {
  try {
    const res = await api.getActiveTrades();

    dispatch({
      type: GET_ACTIVE_TRADES,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ACTIVE_TRADES_ERROR,
      payload: error,
    });
  }
};

export const updateActiveTrades = () => async (dispatch: any) => {
  try {
    await api.getActiveTrades();
  } catch (error) {
    dispatch({
      type: ACTIVE_TRADES_ERROR,
      payload: error,
    });
  }
};

export const getActiveTradesPerMonth = () => async (dispatch: any) => {
  try {
    const res = await api.activeTradesPerMonth();

    dispatch({
      type: GET_ACTIVE_TRADES_PER_MONTH,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: ACTIVE_TRADES_PER_MONTH_ERROR,
      payload: error,
    });
  }
};

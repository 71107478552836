import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { GeneralState } from '../../models/State';
import DBTag from '../DBTag/DBTag';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';

interface ViewParticipantModalProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const ViewParticipantModal: React.FC<ViewParticipantModalProps> = ({
  children,
  ...rest
}) => {
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);

  const renderIsManagerIcon = (isManager: boolean) => {
    return isManager ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
  };

  const renderGroupTags = (groups: string[] | string) => {
    return currentRow.groups.length !== 0 && typeof groups !== 'string' ? (
      groups.map((group) => (
        <DBTag key={group} theme="light" isInModal={true}>
          {group}
        </DBTag>
      ))
    ) : groups.length > 0 ? (
      <DBTag theme="light" isInModal={true}>
        {groups}
      </DBTag>
    ) : (
      ''
    );
  };

  return (
    <DBModalHeader
      title="View Participant"
      className="ViewParticipantModal"
      {...rest}
    >
      <ModalTextField
        title="Is Manager"
        description={renderIsManagerIcon(currentRow.isManager)}
      />
      <ModalTextField title="First Name" description={currentRow.firstName} />
      <ModalTextField title="Last Name" description={currentRow.lastName} />
      <ModalTextField title="Job Title" description={currentRow.jobTitle} />
      <ModalTextField title="Email" description={currentRow.email} />
      <ModalTextField
        title="Groups"
        description={renderGroupTags(currentRow.groups)}
      />
      <ModalTextField
        title="Approval Group"
        description={
          currentRow.approvalGroup && (
            <DBTag
              key={currentRow.approvalGroup}
              theme="light"
              isInModal={true}
            >
              {currentRow.approvalGroup}
            </DBTag>
          )
        }
      />
    </DBModalHeader>
  );
};

export default ViewParticipantModal;

import React from 'react';
import { Table } from 'antd';
import DBTableText from '../DBTableText/DBTableText';
import { Restriction, TradeRequest } from '../../api/backend-api';
import { Link } from 'react-router-dom';
import DBTag from '../DBTag/DBTag';

import './RestrictionsSummaryTable.less';
import { compareRestrictions, formatDate } from '../../utils/Utils';
import { Status } from '../../models/Status';

interface RestrictionsSummaryTableProps {
  restrictions: Restriction[];
  loading: boolean;
}

const RestrictionsSummaryTable: React.FC<RestrictionsSummaryTableProps> = ({
  restrictions,
  loading,
}) => {
  const rowToShow = (restrictions: Restriction[]) => {
    return [...restrictions]
      .filter((res) => res.status === 'ACTIVE' || res.status === 'FUTURE')
      .sort(compareRestrictions)
      .slice(0, 5);
  };

  const getWidthGroups = () => {
    const numberOfGroupsByRestriction: number[] = [];
    rowToShow(restrictions).map((restriction: Restriction) =>
      numberOfGroupsByRestriction.push(
        restriction.groups ? restriction.groups.length : 0,
      ),
    );
    const maxNumberOfGroups = Math.max(...numberOfGroupsByRestriction);

    if (maxNumberOfGroups === 0 || maxNumberOfGroups === 1) {
      return 150;
    } else if (maxNumberOfGroups === 2) {
      return 200;
    } else {
      return 280;
    }
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string, record: TradeRequest) => (
        <DBTableText mainText={text} />
      ),
    },
    {
      key: 'groups',
      title: 'Groups',
      dataIndex: 'groups',
      className: 'GroupsColumn',
      width: getWidthGroups(),
      render: (groups: string[]) => (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          {groups.map((group: any, index: number) => {
            if (index < 3) {
              return (
                <DBTag key={group} theme="dark">
                  {group}
                </DBTag>
              );
            } else {
              return (
                <span
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    width: 30,
                    fontSize: '0.8rem',
                    marginRight: -25,
                  }}
                >
                  + {groups.length - 3}
                </span>
              );
            }
          })}
        </span>
      ),
    },
    {
      key: 'restrictedWindow',
      title: 'Restricted Window',
      dataIndex: 'restrictedWindow',
      width: 220,
      render: (text: string, record: TradeRequest) => (
        <DBTableText
          mainText={`${formatDate(
            record.startDate,
            'd MMM yyyy',
          )} - ${formatDate(record.endDate, 'd MMM yyyy')}`}
        />
      ),
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      className: 'StatusColumn',
      width: 78,
      render: (status: string) => (
        <DBTag status={status as Status}>{status}</DBTag>
      ),
    },
  ];

  return (
    <div className="RestrictionsSummaryContainer">
      <div className="RestrictionsSummaryHeader">
        <h3 className="title">Restrictions Summary</h3>
        <Link to="/restrictions">View all</Link>
      </div>
      <Table
        className="RestrictionsSummaryTable"
        size="large"
        rowKey="id"
        pagination={false}
        loading={loading}
        columns={columns as any}
        dataSource={rowToShow(restrictions)}
      />
    </div>
  );
};

export default RestrictionsSummaryTable;

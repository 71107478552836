import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { rootReducer } from '../reducers';

// Automatically adds the thunk middleware and the Redux DevTools extension
export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;


import { api } from '../api/api';
import { User } from '../api/backend-api';
import { getFiltersValues } from '../utils/dataUtils';
import { GET_USERS, USERS_ERROR } from './types';

export const getAllUsers = () => async (dispatch: any) => {
  try {
    const res = await api.getAllUsers();
    const filterValues = getFiltersValues(
      ['groups', 'isRestricted', 'isManager', 'approvalGroup'],
      res,
    );

    dispatch({
      type: GET_USERS,
      payload: res,
      filterValues,
    });
  } catch (error) {
    dispatch({
      type: USERS_ERROR,
      payload: error,
    });
  }
};

export const addUser = (user: User) => async (dispatch: any) => {
  try {
    await api.addUser(user);
  } catch (error) {
    dispatch({
      type: USERS_ERROR,
      payload: error,
    });
  }
};

export const updateUser =
  (userId: string, user: User) => async (dispatch: any) => {
    try {
      await api.updateUser(userId, user);
    } catch (error) {
      dispatch({
        type: USERS_ERROR,
        payload: error,
      });
    }
  };

export const removeUser = (userId: string) => async (dispatch: any) => {
  try {
    await api.removeUser(userId);
  } catch (error) {
    dispatch({
      type: USERS_ERROR,
      payload: error,
    });
  }
};

import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import './DetailsForm.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { api } from '../../api/api';
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import DBPhoneInputField from '../DBForm/DBPhoneInputField';
import { isValidPhoneNumber } from 'libphonenumber-js/min';
import { isAfter } from 'date-fns';
import { Loader } from '../Loader/Loader';

interface Values {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  companyName: string;
}

const data = [
  {
    title: 'Access to all features',
  },
  {
    title: 'Login and experience Drawbridge for 14 days',
  },
  {
    title: 'Instructions sent directly to your inbox',
  },
  {
    title: 'Your account comes populated with sample data',
  },
  {
    title: 'Support available to help you make the most of your trial',
  },
];

const DetailsForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const clientAuthCodeLocalStorageObject =
      localStorage.getItem('clientAuthCode');

    if (!clientAuthCodeLocalStorageObject) {
      navigate('/error');
      return;
    }

    const clientAuthCodeLocalStorage = JSON.parse(
      clientAuthCodeLocalStorageObject as string,
    ).code;
    const tokenExpirationTime = JSON.parse(
      clientAuthCodeLocalStorageObject as string,
    ).expiryTime;

    if (isAfter(new Date(), new Date(tokenExpirationTime))) {
      navigate('/error');
      return;
    }

    const clientAuthCodePathName = window.location.pathname
      .slice(1)
      .split('/')[2];

    const emailVerificationCode = window.location.pathname
      .slice(1)
      .split('/')[1];

    setEmailVerificationCode(emailVerificationCode);

    if (clientAuthCodeLocalStorage !== clientAuthCodePathName) {
      navigate('/error');
      return;
    }

    return () => localStorage.removeItem('clientAuthCode');
  }, []);

  return (
    
    <div className="DetailsForm">
      <ConfigProvider theme={{
        components: {
          Button: {
            colorPrimary: "#75e6a8",
            colorPrimaryActive: "#75e6a8",
            colorPrimaryHover: '#a2f2c4',
          },
        }
      }}>
      <Button
        className="backButton"
        type="primary"
        size="large"
        shape="circle"
        icon={<LeftOutlined />}
        onClick={() => navigate('/')}
      />
    </ConfigProvider>
      <img
        className="wave"
        src={process.env.PUBLIC_URL + '/images/wave.svg'}
        alt="Wave"
      />
      <Formik
        validate={async (values) => {
          const errors: any = {};
          if (values.firstName.length <= 0) {
            errors.firstName = 'You must enter a first name';
          }
          if (values.lastName.length <= 0) {
            errors.lastName = 'You must enter a last name';
          }
          if (!values.phoneNumber || values.phoneNumber.length <= 0) {
            errors.phoneNumber = 'You must enter your mobile number';
          } else if (!isValidPhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = 'Invalid phone number';
          }
          if (values.companyName.length <= 0) {
            errors.companyName = 'You must enter the name of your company';
          }
          if (values.password.length < 8) {
            errors.password =
              'Your password must be at least 8 characters long';
          } else if (
            // eslint-disable-next-line no-useless-escape
            !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\.\,\/\<\>\?\;\:\'\"\[\]\{\}\-\+\=\_\`\~\|!@#\$%\^&\*])(?=.{8,})/.test(
              values.password,
            )
          ) {
            errors.password =
              'Your password must contain one uppercase, one lowercase, one number and one special case character';
          }

          return errors;
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          password: '',
          companyName: '',
        }}
        onSubmit={(values, actions) => {
          setIsLoading(true);
          api
            .signUp({ ...values, emailVerificationCode })
            .then((token) => {
              localStorage.setItem('@drawbridge_apiBearer', token as string);
              setIsLoading(false);
              navigate('/overview');
              window.location.reload();
              localStorage.removeItem('clientAuthCode');
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              navigate('/failed');
            });
          actions.setSubmitting(false);
        }}
      >
        {(props: FormikProps<Values>) => {
          const ref = React.createRef();
          return (
            <div className="FormContainer">
              <Form className="ant-form ant-form-vertical">
                <h3 className="formTitle">Try Drawbridge for free today</h3>
                {isLoading ? (
                  <Loader style={{ minHeight: 100 }} />
                ) : (
                  <>
                    <DBInputTextField
                      name="firstName"
                      type="text"
                      label="First Name"
                    />
                    <DBInputTextField
                      name="lastName"
                      type="text"
                      label="Last Name"
                    />
                    <DBPhoneInputField
                      ref={ref}
                      international
                      defaultCountry="AU"
                      countryCallingCodeEditable={false}
                      label="Phone Number"
                      name="phoneNumber"
                      onChange={(e: any) =>
                        props.setFieldValue('phoneNumber', e)
                      }
                    />
                    <DBInputTextField
                      name="password"
                      type="password"
                      label="Password"
                    />
                    <DBInputTextField
                      name="companyName"
                      type="text"
                      label="Company Name"
                    />
                  </>
                )}
                {!isLoading ? (
                  <div className="buttonsContainer">
                    <input
                      type="submit"
                      disabled={!(props.dirty && props.isValid)}
                      value="Create"
                      style={{ height: 48 }}
                      className="input-btn ant-btn-lg DBButton"
                    />
                  </div>
                ) : null}
              </Form>
              <div className="rightForm">
                <img
                  className="DBImage"
                  src={
                    process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'
                  }
                  alt="Drawbridge Logo"
                />
                <h3 className="formTitleRight">Included in your free trial</h3>
                <div className="descriptionContainer">
                  {data.map((d) => (
                    <div key={d.title} className="row">
                      <span className="icon">
                        <CheckCircleOutlined />
                      </span>
                      <span className="description">{d.title}</span>
                    </div>
                  ))}
                </div>
                <div className="bottomText">
                  You trial account expires in 14 days
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default DetailsForm;

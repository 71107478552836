import { Restriction } from './../api/backend-api/api';
import { api } from '../api/api';
import { GET_RESTRICTIONS, RESTRICTIONS_ERROR } from './types';
import { getFiltersValues } from '../utils/dataUtils';

export const getAllRestrictions =
  (email?: string, status?: 'ACTIVE' | 'FUTURE' | 'ENDED', options?: any) =>
  async (dispatch: any) => {
    try {
      const res = await api.getAllRestrictions(email, status, options);
      const filterValues = getFiltersValues(['groups'], res);

      dispatch({
        type: GET_RESTRICTIONS,
        payload: res,
        filterValues,
      });
    } catch (error) {
      dispatch({
        type: RESTRICTIONS_ERROR,
        payload: error,
      });
    }
  };

export const addRestriction =
  (restriction: Restriction) => async (dispatch: any) => {
    try {
      await api.addRestriction(restriction);
    } catch (error) {
      dispatch({
        type: RESTRICTIONS_ERROR,
        payload: error,
      });
    }
  };

export const updateRestriction =
  (userId: string, newRestriction: Restriction) => async (dispatch: any) => {
    try {
      await api.updateRestriction(userId, newRestriction);
    } catch (error) {
      dispatch({
        type: RESTRICTIONS_ERROR,
        payload: error,
      });
    }
  };

export const removeRestriction =
  (restrictionId: string) => async (dispatch: any) => {
    try {
      await api.removeRestriction(restrictionId);
    } catch (error) {
      dispatch({
        type: RESTRICTIONS_ERROR,
        payload: error,
      });
    }
  };

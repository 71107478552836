import {
  UserBulkImportJob,
  UserBulkImportJobErrorCodeEnum,
} from '../api/backend-api';

export const translateErrorDetails = (jobRecord: UserBulkImportJob) => {
  const bulkImportErrorCodes = new Map<UserBulkImportJobErrorCodeEnum, string>([
    [
      UserBulkImportJobErrorCodeEnum.MissingFieldError,
      `Missing field error: Import csv was sent with a missing ${jobRecord.errorObject} field.`,
    ],
    [
      UserBulkImportJobErrorCodeEnum.FieldPatternError,
      `Field pattern error: Import csv was sent with a malformed ${jobRecord.errorObject} field.`,
    ],
    [
      UserBulkImportJobErrorCodeEnum.EmailNotAvailableError,
      "Email not available error: An email address entered in the import csv is already in use by another user.",
    ],
    [
      UserBulkImportJobErrorCodeEnum.GroupNotFound,
      'Group not found: A group entered in the import csv does not exist.',
    ],
    [
      UserBulkImportJobErrorCodeEnum.ApprovalGroupNotFound,
      'Approval group not found: An approval group entered in the import csv does not exist.',
    ],
    [
      UserBulkImportJobErrorCodeEnum.InsufficientPrivilege,
      'Insufficient privilege: The logged in user does not have enough privilege to perform this action.',
    ],
    [
      UserBulkImportJobErrorCodeEnum.UnknownValidationError,
      'Unknown validation error: An error has occurred during the import due to malformed data in the csv.',
    ],
    [
      UserBulkImportJobErrorCodeEnum.UnknownSystemError,
      'Unknown system error: An unexpected error has occurred during the csv import.',
    ],
  ]);

  return bulkImportErrorCodes.get(
    jobRecord.errorCode || UserBulkImportJobErrorCodeEnum.UnknownSystemError,
  );
};

import React from 'react';
import { Form, Switch } from 'antd';

const DBSwitchField: React.FC<any> = ({
  label,
  disabled,
  onChange,
  ...props
}) => {
  return (
    <Form.Item label={label} colon={false}
      labelCol={{ span: 24 }}>
      <Switch onChange={onChange} {...props} disabled={disabled} />
    </Form.Item>
  );
};

export default DBSwitchField;

import {
  UsersApi,
  GroupsApi,
  RestrictionsApi,
  TradeRequestsApi,
  PublicUsersApi,
  SignUpRequest,
  BulkUpdateUserGroupsRequest,
  ApprovalGroupsApi,
  UserBulkImportJob,
  BulkUpdateUserApprovalGroupRequest,
  ApprovalGroup,
} from './backend-api/api';
import { auth0BaseUrl, auth0ClientId, httpBaseUrl } from './config/config';
import axios, { AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { Group, Restriction, User, TradeRequest } from './backend-api';
import { subDays } from 'date-fns';

// Get Auth0 token from the localstorage in the browser
axios.interceptors.request.use(async function (
  config: InternalAxiosRequestConfig<any>,
): Promise<InternalAxiosRequestConfig<any>> {
  const bearer = localStorage.getItem('@drawbridge_apiBearer');
  return {
    ...config,
    withCredentials: false,
    baseURL: httpBaseUrl,
    headers: {
      Authorization: 'Bearer ' + bearer ?? '',
      ...config.headers,
    } as AxiosRequestHeaders,
  };
});

// Add an axios interceptor to handle 401
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // If it is an unauthorised response error '401'...
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#client_error_responses
    if (error.response.status >= 400 && error.response.status <= 499) {
      // Get the apiBearer from localStorage and runJWTExpCheck()
      window.location.replace('/');
    }
    return error;
  },
);

// Set API configuration
const usersApi = new UsersApi({
  basePath: httpBaseUrl,
} as any);
const groupsApi = new GroupsApi({
  basePath: httpBaseUrl,
} as any);
const restrictionsApi = new RestrictionsApi({
  basePath: httpBaseUrl,
} as any);
const tradeRequestApi = new TradeRequestsApi({
  basePath: httpBaseUrl,
} as any);
const approvalGroupApi = new ApprovalGroupsApi({
  basePath: httpBaseUrl,
} as any);

const publicUserApi = new PublicUsersApi({
  basePath: httpBaseUrl,
} as any);

/**
 * This file is used to store API calls that can then be dynamically imported from the Application.
 */
export const resetPassword = async (email: string) => {
  var options: AxiosRequestConfig = {
    method: 'POST',
    url: auth0BaseUrl + '/dbconnections/change_password',
    headers: { 'content-type': 'application/json' },
    data: {
      client_id: auth0ClientId,
      email: email,
      connection: 'Username-Password-Authentication',
    },
  };

  return axios.request(options);
};

// Public User Api
const signUp = async (obj: SignUpRequest) => {
  return new Promise((resolve, reject) => {
    publicUserApi
      .signUp(obj)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const sendVerificationEmail = async (
  email: string,
  clientAuthCode: string,
  options?: any,
) => {
  return new Promise((resolve, reject) => {
    publicUserApi
      .sendVerificationEmail(email, clientAuthCode, options)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Groups
const getAllGroups = async (): Promise<any> => {
  return new Promise((resolve, reject) => {
    groupsApi
      .listGroups()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getGroup = async (groupId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    groupsApi
      .getGroup(groupId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getGroupByName = async (name: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    groupsApi
      .listGroups(name)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const addGroup = async (group: Group): Promise<any> => {
  return new Promise((resolve, reject) => {
    groupsApi
      .addGroup(group)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const updateGroup = async (groupId: string, group: Group): Promise<any> => {
  return new Promise((resolve, reject) => {
    groupsApi
      .updateGroup(groupId, group)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const removeGroup = async (groupId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    groupsApi
      .removeGroup(groupId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Restricitons
const getAllRestrictions = async (
  email?: string,
  status?: 'ACTIVE' | 'FUTURE' | 'ENDED',
  options?: any,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    restrictionsApi
      .listRestrictions(email, status, options)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getRestriction = async (restrictionId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    restrictionsApi
      .getRestriction(restrictionId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const addRestriction = async (restriction: Restriction): Promise<any> => {
  return new Promise((resolve, reject) => {
    restrictionsApi
      .addRestriction(restriction)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const updateRestriction = async (
  restrictionId: string,
  restriction: Restriction,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    restrictionsApi
      .updateRestriction(restrictionId, restriction)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const removeRestriction = async (estrictionId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    restrictionsApi
      .removeRestriction(estrictionId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// User
const getAllUsers = async (email?: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .listUsers(email)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getUser = async (userId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .getUser(userId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const addUser = async (user: User): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .addUser(user)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const updateUser = async (userId: string, user: User): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .updateUser(userId, user)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const removeUser = async (userId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .removeUser(userId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const bulkUpdateUserGroups = async (
  obj: BulkUpdateUserGroupsRequest,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .bulkUpdateUserGroups(obj)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const bulkUpdateUserApprovalGroup = async (
  obj: BulkUpdateUserApprovalGroupRequest,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .bulkUpdateUserApprovalGroup(obj)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const listUserBulkImportJobs = async (): Promise<UserBulkImportJob[]> => {
  return new Promise((resolve, reject) => {
    usersApi
      .listUserBulkImportJobs()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const csvUploader = async (body: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .bulkImportUsers(body)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const resendUserInvite = async (userId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    usersApi
      .resendUserInvite(userId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const isEmailAvailable = async (email: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    publicUserApi
      .isEmailAvailable(email)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Trade request
const getAllTradeRequests = async (
  email?: string | undefined,
  status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED' | undefined,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    tradeRequestApi
      .listTradeRequests(email, status)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getTradeRequest = async (tradeRequestId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    tradeRequestApi
      .getTradeRequest(tradeRequestId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const addTradeRequest = async (tradeRequest: TradeRequest): Promise<any> => {
  return new Promise((resolve, reject) => {
    tradeRequestApi
      .addTradeRequest(tradeRequest)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const updateTradeRequest = async (
  tradeRequestId: string,
  tradeRequest: TradeRequest,
): Promise<any> => {
  return new Promise((resolve, reject) => {
    tradeRequestApi
      .updateTradeRequest(tradeRequestId, tradeRequest)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const activeTradesPerMonth = async () => {
  const now = new Date();
  const endOfDay = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    0,
    0,
  );
  const todayMinus30 = subDays(endOfDay, 31);

  return new Promise((resolve, reject) => {
    tradeRequestApi
      .listTradeRequests(
        undefined,
        undefined,
        undefined,
        todayMinus30.toISOString(),
        endOfDay.toISOString(),
      )
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const getActiveTrades = async () => {
  return new Promise((resolve, reject) => {
    tradeRequestApi
      .listTradeRequests(undefined, undefined, true)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Approval Groups
const getAllApprovalGroups = async () => {
  return new Promise((resolve, reject) => {
    approvalGroupApi
      .listApprovalGroups()
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const updateApprovalGroup = async (
  approvalGroupId: string,
  approvalGroup: ApprovalGroup,
) => {
  return new Promise((resolve, reject) => {
    approvalGroupApi
      .updateApprovalGroup(approvalGroupId, approvalGroup)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const addApprovalGroup = async (approvalGroup: ApprovalGroup) => {
  return new Promise((resolve, reject) => {
    approvalGroupApi
      .addApprovalGroup(approvalGroup)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

const deleteApprovalGroup = async (approvalGroupId: string) => {
  return new Promise((resolve, reject) => {
    approvalGroupApi
      .removeApprovalGroup(approvalGroupId)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

/**
 * api
 * Accumlates all api methods into a parent object
 * e.g. api.getUser(...)
 */
export const api = {
  signUp,
  getAllGroups,
  addGroup,
  getGroup,
  getGroupByName,
  removeGroup,
  updateGroup,
  addRestriction,
  getRestriction,
  getAllRestrictions,
  removeRestriction,
  updateRestriction,
  addUser,
  getUser,
  isEmailAvailable,
  getAllUsers,
  removeUser,
  resendUserInvite,
  bulkUpdateUserGroups,
  bulkUpdateUserApprovalGroup,
  csvUploader,
  listUserBulkImportJobs,
  updateUser,
  addTradeRequest,
  getTradeRequest,
  getAllTradeRequests,
  updateTradeRequest,
  activeTradesPerMonth,
  getActiveTrades,
  getAllApprovalGroups,
  addApprovalGroup,
  updateApprovalGroup,
  deleteApprovalGroup,
  sendVerificationEmail,
};

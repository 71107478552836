import React, { useEffect } from 'react';
import { Table } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { User } from '../../api/backend-api';
import { getAllUsers } from '../../actions/userActions';
import DBTableText from '../DBTableText/DBTableText';
import DBTag from '../DBTag/DBTag';
import { formatDate, stringSearch } from '../../utils/Utils';
import DBPopover from '../DBPopover/DBPopover';

import './ParticipantsTable.less';
import {
  changeTab,
  cleanSelectedRows,
  setCurrentRow,
  setSelectedRows,
  selectAllRows,
  setSearchString,
  unselectRow,
} from '../../actions/uiActions';
import { ModalType } from '../../models/Modal';
import { GeneralState, UserState } from '../../models/State';
import { useAuth } from '../../context/auth/authContext';
import { getAllGroups } from '../../actions/groupActions';
import { getAllApprovalGroups } from '../../actions/approvalGroupActions';
import { useAppDispatch } from '../../hooks/reactRedux';

const ParticipantsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const {
    users: participants,
    loading,
    filterValues,
  } = useSelector<GeneralState, UserState>((state) => state.users);
  const { searchString, rowsSelected, filterModeActive } = useSelector<
    GeneralState,
    any
  >((state) => state.ui);

  useEffect(() => {
    dispatch(changeTab('Participants'));
    dispatch(getAllGroups());
    dispatch(getAllApprovalGroups());
    dispatch(getAllUsers());

    return () => {
      dispatch(setSearchString(''));
      dispatch(cleanSelectedRows());
    };
  }, [dispatch]);

  const handleRow = (record: any) => {
    return {
      onClick: () => {
        dispatch(setCurrentRow(record));
      },
    };
  };

  const setPopoverOptions = (participant: any | undefined) => {
    if (participant.email === user?.email) {
      return [
        { name: 'View', action: ModalType.ViewParticipantModal },
        { name: 'Edit', action: ModalType.EditParticipantModal },
        { name: 'Resend Invite', action: ModalType.ResendUserInviteModal },
      ];
    }

    return [
      { name: 'View', action: ModalType.ViewParticipantModal },
      { name: 'Edit', action: ModalType.EditParticipantModal },
      { name: 'Resend Invite', action: ModalType.ResendUserInviteModal },
      { name: 'Delete', action: ModalType.DeleteModal },
    ];
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      render: (text: string, record: User) => (
        <DBTableText
          mainText={`${record.firstName} ${record.lastName}`}
          subText={record.jobTitle}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          `${a.firstName} ${a.lastName}`
            .toLowerCase()
            .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase()),
      },
    },
    {
      key: 'groups',
      title: 'Groups',
      dataIndex: 'groups',
      width: '25%',
      render: (groups: string[]) => (
        <span
          style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
        >
          {groups.map((group) => (
            <DBTag key={group} theme="dark">
              {group}
            </DBTag>
          ))}
        </span>
      ),
      filters: !loading ? filterValues['groups'] : [],
      onFilter: (value: any, record: any) => record.groups.includes(value),
    },
    {
      key: 'lastRequest',
      title: 'Last Request',
      dataIndex: 'lastRequest',
      render: (text: string, record: User) => (
        <DBTableText
          mainText={`${formatDate(record.lastTradeRequestedOn, 'd MMM yyyy')}`}
          subText={`${formatDate(record.lastTradeRequestedOn, 'h:mm a')}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          Number(new Date(a.lastTradeRequestedOn)) -
          Number(new Date(b.lastTradeRequestedOn)),
      },
    },
    {
      key: 'lastLoggedIn',
      title: 'Last Logged In',
      dataIndex: 'lastLoggedIn',
      render: (text: string, record: User) => (
        <DBTableText
          mainText={`${formatDate(record.lastLoggedIn, 'd MMM yyyy')}`}
          subText={`${formatDate(record.lastLoggedIn, 'h:mm a')}`}
        />
      ),
      sorter: {
        compare: (a: any, b: any) =>
          Number(new Date(a.lastLoggedIn)) - Number(new Date(b.lastLoggedIn)),
      },
    },
    {
      key: 'isRestricted',
      title: 'Restricted',
      dataIndex: 'isRestricted',
      render: (isRestricted: boolean) =>
        isRestricted ? (
          <LockOutlined style={{ color: '#ffffff', fontSize: 20 }} />
        ) : null,
      filters: !loading ? filterValues['isRestricted'] : [],
      onFilter: (value: any, record: any) => record.isRestricted === value,
    },
    {
      key: 'approvalGroup',
      title: 'Approval Group',
      dataIndex: 'approvalGroup',
      render: (approvalGroup: string) => {
        return approvalGroup ? (
          <DBTag theme="dark">{approvalGroup}</DBTag>
        ) : null;
      },
      filters: !loading ? filterValues['approvalGroup'] : [],
      onFilter: (value: any, record: any) =>
        record && record.approvalGroup === value,
    },
    {
      key: 'isManager',
      title: 'Role',
      dataIndex: 'isManager',
      ellipsis: true,
      render: (text: boolean) => (
        <DBTableText mainText={text ? 'Manager' : 'Participant'} />
      ),
      filters: !loading ? filterValues['isManager'] : [],
      onFilter: (value: any, record: any) => record.isManager === value,
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'email',
      width: 50,
      align: 'center',
      render: (text: string, record: any) => (
        <DBPopover options={setPopoverOptions(record) as any} />
      ),
    },
  ];

  const participantsToShow = searchString
    ? participants.filter((pc: any) =>
        stringSearch(searchString, `${pc.firstName} ${pc.lastName}`),
      )
    : participants;

  const onSelectChange = (
    record: any,
    selected: any,
    selectedRows: any,
    nativeEvent: any,
  ) => {
    if (selected) {
      dispatch(setSelectedRows(record));
      return;
    } else {
      dispatch(unselectRow(record));
      return;
    }
  };

  const rowSelection = {
    selectedRowKeys: rowsSelected ? rowsSelected.map((row: any) => row.id) : [],
    onSelect: onSelectChange,
    onSelectAll: (selected: boolean) => {
      if (selected) {
        dispatch(selectAllRows(participants));
        return;
      } else {
        dispatch(cleanSelectedRows());
        return;
      }
    },
  };

  return (
    <Table
      onRow={handleRow}
      className="ParticipantsTable"
      size="small"
      rowKey="id"
      pagination={{ showSizeChanger: false }}
      loading={loading}
      columns={columns as any}
      rowSelection={filterModeActive && rowSelection}
      dataSource={participantsToShow}
      getPopupContainer={() => {
        return document.getElementsByClassName(
          'ParticipantsTable',
        )[0] as HTMLElement;
      }}
    />
  );
};

export default ParticipantsTable;

import React, { useEffect } from 'react';
import DBButton from '../DBButton/DBButton';
import DBRadio from './DBRadio';
import './DBFilter.less';
import {
  cleanSelectedRows,
  setActionFilter,
  setFilterModeActive,
  setSelectedGroup,
} from '../../actions/uiActions';
import { useSelector } from 'react-redux';
import { GeneralState } from '../../models/State';
import DBSelect from './DBSelect';
import { useAppDispatch } from '../../hooks/reactRedux';
import { ConfigProvider } from 'antd';

interface DBFilterProps {
  multiple: boolean;
  placeholder?: string;
  radioValues: string[];
  selectValues: string[];
  onSubmit: () => void;
}

const DBFilter: React.FC<DBFilterProps> = ({
  multiple,
  placeholder = '',
  radioValues,
  selectValues,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();
  const { actionFilter, rowsSelected, groupsSelected } = useSelector<
    GeneralState,
    any
  >((state) => state.ui);

  useEffect(() => {
    dispatch(setActionFilter(radioValues[0]));

    // Close the filter when navigating between tabs
    return () => {
      dispatch(setFilterModeActive(false, undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleChange = (e: any) => {
    if (typeof e === 'string' && multiple === false) {
      e = [e];
    }

    return e.target
      ? dispatch(setActionFilter(e.target.value))
      : dispatch(setSelectedGroup(e));
  };

  const handleCancel = () => {
    dispatch(cleanSelectedRows());
    dispatch(setFilterModeActive(false, undefined));
    dispatch(setSelectedGroup(undefined));
  };

  return (
    <div className="DBFilter">
      <DBRadio
        values={radioValues}
        onChange={handleChange}
        selectedValue={actionFilter}
      />
      <DBSelect
        mode={multiple ? "multiple" : undefined}
        placeholder={placeholder}
        options={selectValues.map((value: string) => ({
          key: value,
          label: value,
          value: value,
        }))}
        collapse={true}
        value={groupsSelected}
        onChange={handleChange}
        style={{ minWidth: 260, marginLeft: 10 }}
        popupClassName="DBSelectDropdown"
      />
      <ConfigProvider theme={{
        components: {
          Button: {
            colorPrimary: '#000000',
            colorBgContainerDisabled: '#ffffff',
          },
        }
      }}>
        <DBButton
          style={{ marginLeft: '0.5rem' }}
          variety="create"
          disabled={
            (groupsSelected && groupsSelected.length === 0) ||
            (rowsSelected && rowsSelected.length === 0)
          }
          onClick={() => onSubmit()}
        >
          Submit
        </DBButton>
      </ConfigProvider>

      <DBButton
        style={{ marginLeft: '0.5rem', color: '#000' }}
        variety="cancel"
        onClick={handleCancel}
      >
        Cancel
      </DBButton>
    </div>
  );
};

export default DBFilter;

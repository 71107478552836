import {
  CHANGE_TAB,
  CLOSE_MODAL,
  OPEN_MODAL,
  SET_CURRENT_ROW,
  SET_MODAL_WIDTH,
  SET_PENDING_REQUESTS_AMOUNT,
  SET_SEARCH_STRING,
  SET_SELECTED_ROWS,
  FILTER_MODE_ACTIVE,
  SET_ACTION_FILTER,
  SET_SELECTED_GROUPS,
  SELECT_ALL_ROWS,
  CLEAN_SELECTED_ROWS,
  UNSELECT_ROW,
} from './../actions/types';

const initialState = {
  currentTab: '',
  currentRow: null,
  loading: true,
  isModalOpen: false,
  modalType: null,
  modalData: null,
  modalWidth: undefined,
  searchString: undefined,
  pendingRequestsAmount: null,
  rowsSelected: [],
  filterModeActive: false,
  actionFilter: null,
  groupsSelected: [],
  filterType: undefined,
};

export const uiReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CHANGE_TAB:
      return { ...state, currentTab: action.payload };
    case SET_CURRENT_ROW:
      return { ...state, currentRow: action.payload, loading: false };
    case SET_MODAL_WIDTH:
      return { ...state, modalWidth: action.payload };
    case SET_SEARCH_STRING:
      return { ...state, searchString: action.payload };
    case SET_PENDING_REQUESTS_AMOUNT:
      return { ...state, pendingRequestsAmount: action.payload };
    case SET_SELECTED_ROWS:
      return {
        ...state,
        rowsSelected: [...state.rowsSelected, action.payload],
      };
    case UNSELECT_ROW:
      const deletedRowState = state.rowsSelected.filter(
        (row: any) => row.id !== action.payload.id,
      );
      return {
        ...state,
        rowsSelected: [...deletedRowState],
      };
    case SELECT_ALL_ROWS:
      return {
        ...state,
        rowsSelected: action.payload,
      };
    case CLEAN_SELECTED_ROWS:
      return {
        ...state,
        rowsSelected: [],
      };
    case FILTER_MODE_ACTIVE:
      return {
        ...state,
        filterModeActive: action.payload.value,
        filterType: action.payload.filterType,
      };
    case SET_SELECTED_GROUPS:
      return { ...state, groupsSelected: action.payload };
    case SET_ACTION_FILTER:
      return { ...state, actionFilter: action.payload };
    case OPEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
        modalType: action.payload,
        modalData: action.data,
      };
    case CLOSE_MODAL:
      return { ...state, isModalOpen: false, modalType: null };
    default:
      return state;
  }
};

// TODO: Try to convert all the actions into a unique enum
export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUP = 'GET_GROUP';
export const GROUPS_ERROR = 'GROUPS_ERROR';

export const GET_APPROVAL_GROUPS = 'GET_APPROVAL_GROUPS';
export const APPROVAL_GROUPS_ERROR = 'APPROVAL_GROUPS_ERROR';

export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const USERS_ERROR = 'USERS_ERROR';

export const GET_RESTRICTIONS = 'GET_RESTRICTIONS';
export const RESTRICTIONS_ERROR = 'RESTRICTIONS_ERROR';

export const GET_TRADE_REQUESTS = 'GET_TRADE_REQUESTS';
export const TRADE_REQUESTS_ERROR = 'TRADE_REQUESTS_ERROR';

export const GET_ACTIVE_TRADES = 'GET_ACTIVE_TRADES';
export const ACTIVE_TRADES_ERROR = 'ACTIVE_TRADES_ERROR';

export const GET_ACTIVE_TRADES_PER_MONTH = 'GET_ACTIVE_TRADES_PER_MONTH';
export const ACTIVE_TRADES_PER_MONTH_ERROR = 'ACTIVE_TRADES_PER_MONTH_ERROR';

export const CHANGE_TAB = 'CHANGE_TAB';
export const SET_CURRENT_ROW = 'SET_CURRENT_ROW';
export const SET_MODAL_WIDTH = 'SET_MODAL_WIDTH';
export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';
export const SET_PENDING_REQUESTS_AMOUNT = 'SET_PENDING_REQUESTS_AMOUNT';
export const SET_SELECTED_ROWS = 'SET_SELECTED_ROWS';
export const UNSELECT_ROW = 'UNSELECT_ROW';
export const SET_ACTION_FILTER = 'SET_ACTION_FILTER';
export const SET_SELECTED_GROUPS = 'SET_SELECTED_GROUPS';
export const SELECT_ALL_ROWS = 'SELECT_ALL_ROWS';
export const CLEAN_SELECTED_ROWS = 'CLEAN_SELECTED_ROWS';
export const FILTER_MODE_ACTIVE = 'FILTER_MODE_ACTIVE';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

import { TradeRequest } from './../api/backend-api/api';
import {
  CHANGE_TAB,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_CURRENT_ROW,
  SET_MODAL_WIDTH,
  SET_SEARCH_STRING,
  SET_PENDING_REQUESTS_AMOUNT,
  SET_SELECTED_ROWS,
  FILTER_MODE_ACTIVE,
  SET_SELECTED_GROUPS,
  SET_ACTION_FILTER,
  CLEAN_SELECTED_ROWS,
  SELECT_ALL_ROWS,
  UNSELECT_ROW,
} from './types';
import { ModalType } from './../models/Modal';
export interface ModalAction {
  type: string;
  payload?: ModalType;
  data?: any;
}

export enum FilterType {
  groups = 'groups',
  approvalGroup = 'approvalGroup',
}

export const changeTab = (tab: string) => {
  return {
    type: CHANGE_TAB,
    payload: tab,
  };
};

export const openModal = (modalType: ModalType, data?: any): ModalAction => {
  return {
    type: OPEN_MODAL,
    payload: modalType,
    data,
  };
};

export const closeModal = (): ModalAction => {
  return {
    type: CLOSE_MODAL,
  };
};

export const setCurrentRow = (row: any) => {
  return {
    type: SET_CURRENT_ROW,
    payload: row,
  };
};

export const setModalWidth = (width: number | undefined) => {
  return {
    type: SET_MODAL_WIDTH,
    payload: width,
  };
};

export const setSearchString = (word: string) => {
  return {
    type: SET_SEARCH_STRING,
    payload: word,
  };
};

export const setPendingRequestsAmount = (pendingRequests: TradeRequest[]) => {
  return {
    type: SET_PENDING_REQUESTS_AMOUNT,
    payload: pendingRequests.length,
  };
};

export const setSelectedRows = (selectedRowKeys: object) => {
  return {
    type: SET_SELECTED_ROWS,
    payload: selectedRowKeys,
  };
};

export const unselectRow = (row: object) => {
  return {
    type: UNSELECT_ROW,
    payload: row,
  };
};

export const selectAllRows = (allRows: object[]) => {
  return {
    type: SELECT_ALL_ROWS,
    payload: allRows,
  };
};

export const cleanSelectedRows = () => {
  return {
    type: CLEAN_SELECTED_ROWS,
  };
};

export const setFilterModeActive = (
  value: boolean,
  filterType?: FilterType,
) => {
  return {
    type: FILTER_MODE_ACTIVE,
    payload: { value, filterType },
  };
};

export const setSelectedGroup = (value: any) => {
  return {
    type: SET_SELECTED_GROUPS,
    payload: value,
  };
};

export const setActionFilter = (value: string) => {
  return {
    type: SET_ACTION_FILTER,
    payload: value,
  };
};

import { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Empty } from 'antd';
import { formatDate } from '../../../utils/Utils';
import { translateErrorDetails } from '../../../utils/bulkImportErrorMessages';
import DBTag from '../../DBTag/DBTag';
import { UserBulkImportJob } from '../../../api/backend-api';
import { api } from '../../../api/api';
import Box from '../../Box/Box';
import Flex from '../../Flex/Flex';
import './UserBulkImportJobElement.less';

type CollapsibleType = 'header' | 'icon' | 'disabled';

const userBulkImportJobElement = (el: UserBulkImportJob) => (
  <Flex
    key={el.id}
    justifyContent="space-between"
    alignItems="center"
    padding="8px 12px"
    cursor="unset"
    _hover={{
      backgroundColor: '#f7fafc',
    }}
  >
    <Box>
      <div>Uploaded on {formatDate(el.createdOn, 'd MMM yyyy')}</div>
      <div>
        Status:&nbsp;
        {(el.status === 'IN_PROGRESS' || el.status === 'PENDING') &&
          `Processing ${el.participantCount} users`}
        {el.status === 'COMPLETED' &&
          `Successfully uploaded ${el.participantCount} users`}
        {el.status === 'ERROR' &&
          `Errors found in upload (${el.participantCount} users)`}
      </div>
    </Box>
    <DBTag
      theme={
        (el.status === 'COMPLETED' && 'approved') ||
        (el.status === 'ERROR' && 'cancelled') ||
        'future'
      }
    >
      {(el.status === 'IN_PROGRESS' || el.status === 'PENDING') && (
        <LoadingOutlined
          style={{
            fontSize: 12,
            paddingLeft: 2,
            paddingRight: 4,
          }}
          spin
        />
      )}
      {el.status === 'ERROR' && 'Issues Found'}
      {el.status === 'IN_PROGRESS' && 'In Progress'}
      {el.status === 'PENDING' && 'Pending'}
      {el.status === 'COMPLETED' && 'Completed'}
    </DBTag>
  </Flex>
);

const UserBulkImportJobElement = () => {
  const [bulkImport, setBulkImport] = useState<UserBulkImportJob[]>();

  let bulkImportJob = async () => {
    let res = await api.listUserBulkImportJobs();
    setBulkImport(res);
  };

  useEffect(() => {
    bulkImportJob();
    const intervalId = setInterval(() => {
      bulkImportJob();
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const getCollapseItems = () => {
    if (!bulkImport) {
      return [];
    }

    return bulkImport
      .sort(
        (a, b) =>
          Number(new Date(b.createdOn as string)) -
          Number(new Date(a.createdOn as string)),
      )
      .map((job) => {
        if (job.status === 'ERROR' && 'Issues Found') {
          return { key: job.id || '', label: userBulkImportJobElement(job), children: <div>{translateErrorDetails(job)}</div> }
        }
        return { key: job.id || '', label: userBulkImportJobElement(job), showArrow: false, collapsible: 'icon' as CollapsibleType}
      });
  };

  const collapseItems = getCollapseItems();



  return (
    <Box overflow="hidden">
      <Box maxHeight="232px" overflow="scroll">
        {!bulkImport ? (
          <>
            <div>
              Upload tasks will show here, alongside their upload status{' '}
            </div>
            <Flex width="100%" minHeight="110px" justifyContent="center">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Flex>
          </>
        ) : (
          <Collapse className="user-bulk-import-collapse" expandIconPosition={'end'} bordered={false} ghost={true} items={collapseItems}>

          </Collapse>
        )}
      </Box>
    </Box>
  );
};

export default UserBulkImportJobElement;

import { Button, ConfigProvider, Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  changeTab,
  FilterType,
  openModal,
  setFilterModeActive,
  setSearchString,
} from '../../actions/uiActions';
import { TradeRequest } from '../../api/backend-api';
import { ModalType } from '../../models/Modal';
import { GeneralState } from '../../models/State';
import { Tabs as TabsNames } from '../../models/Tabs';
import { validateRequest } from '../../utils/validateRequest';
import DataTable from '../DataTable/DataTable';
import DBBadge from '../DBBadge/DBBadge';
import DBButton from '../DBButton/DBButton';
import DBFilter from '../DBFilters/DBFilter';
import DBSearchInput from '../DBSearchInput/DBSearchInput';
import { CSVLink } from 'react-csv';
import { populateTransactionsIntoCSV } from '../../utils/dataUtils';
import './DBTab.less';
import { useAppDispatch } from '../../hooks/reactRedux';
interface DBTabProps {
  tabs: TabsNames[] | null;
}

const DBTab: React.FC<DBTabProps> = ({ tabs }) => {
  const dispatch = useAppDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const pathName = useLocation().pathname.slice(1);
  const { Participants, PendingRequests, Transactions } = TabsNames;
  const currentDate = new Date();

  const { currentTab, filterModeActive, filterType } = useSelector<
    GeneralState,
    any
  >((state) => state.ui);
  const { groups, loading: loadingGroups } = useSelector<GeneralState, any>(
    (state) => state.groups,
  );
  const { approvalGroups, loading: loadingApprovalGroups } = useSelector<
    GeneralState,
    any
  >((state) => state.approvalGroups);
  const { tradeRequests, loading } = useSelector<GeneralState, any>(
    (state) => state.tradeRequests,
  );
  const { rowsSelected } = useSelector<GeneralState, any>((state) => state.ui);

  const handleChangeTab = (e: string) => {
    if (isMounted) validateRequest(e, dispatch);
    dispatch(changeTab(e));
  };

  const handleChangeSearch = (word: string) => {
    dispatch(setSearchString(word));
  };

  useEffect(() => {
    setIsMounted(true);
  }, [isMounted]);

  const hadleCountChange = () => {
    if (pathName === 'requests' && !loading) {
      const pendingRequests = tradeRequests.filter(
        (tradeRequest: TradeRequest) => tradeRequest.status === 'PENDING',
      );
      return pendingRequests.length;
    } else {
      return 0;
    }
  };

  const getAllGroupNames = () => {
    return !loadingGroups ? groups.map((group: any) => group.name) : [];
  };
  const getAllApprovalGroupsNames = () => {
    return !loadingApprovalGroups
      ? approvalGroups.map((group: any) => group.name)
      : [];
  };

  const getPlaceholder = () => {
    return currentTab === Participants ? 'Search name' : 'Search';
  };

  const handleSubmit = async () => {
    dispatch(openModal(ModalType.ConfirmationModal, rowsSelected.length));
  };

  const transactionsCSVTable = populateTransactionsIntoCSV(tradeRequests);

  const getTabItems = (): TabsProps['items'] => {
    if (tabs == null) {
      return [];
    }

    return tabs.map((tab: TabsNames) => {
      const key: TabsNames = tab;
      let label: JSX.Element;
      const children: JSX.Element = <DataTable tab={tab} />

      if (tab !== PendingRequests) {
        label = <span>{tab}</span>
      }
      else {
        label = (
          <span>
            {tab}
            <DBBadge
              className="tab-badge"
              onCountChange={hadleCountChange}
            />
          </span>
        )
      }

      return { key, label, children };
    });
  };

  const tabItems: TabsProps['items'] = getTabItems();

  const renderExtraContent = () => {
    return (
      <span className="tabExtraContent">
        {currentTab === Participants ? (
          filterModeActive && filterType === FilterType.groups ? (
            <DBFilter
              multiple
              placeholder="Select groups"
              selectValues={getAllGroupNames()}
              radioValues={['add', 'remove']}
              onSubmit={handleSubmit}
            />
          ) : filterModeActive && filterType === FilterType.approvalGroup ? (
            <DBFilter
              multiple={false}
              placeholder="Select approval groups"
              selectValues={getAllApprovalGroupsNames()}
              radioValues={['add', 'remove']}
              onSubmit={handleSubmit}
            />
          ) : (
            <>
              <ConfigProvider theme={{
                components: {
                  Button: {
                    colorPrimaryHover: '#ffffff',
                    colorPrimaryActive: '#ffffff',
                  },
                }
              }}>
                <DBButton
                  onClick={() =>
                    dispatch(setFilterModeActive(true, FilterType.groups))
                  }
                >
                  Edit groups
                </DBButton>
                <DBButton
                  style={{ marginLeft: '1rem' }}
                  onClick={() =>
                    dispatch(setFilterModeActive(true, FilterType.approvalGroup))
                  }
                >
                  Edit approval groups
                </DBButton>
              </ConfigProvider>
            </>
          )
        ) : null}
        {currentTab === Transactions ? (

          <CSVLink
            filename={`Total Transactions at ${currentDate}`}
            data={transactionsCSVTable.data}
            headers={transactionsCSVTable.headers}
          >
            <ConfigProvider theme={{
              components: {
                Button: {
                  colorPrimaryHover: '#ffffff',
                  colorPrimaryActive: '#ffffff',
                },
              }
            }}>
              <Button className="DBButton">
                Export Requests
              </Button>
            </ConfigProvider>
          </CSVLink>
        ) : null}

        <DBSearchInput
          style={{ minWidth: 200, marginLeft: '1rem' }}
          placeholder={getPlaceholder()}
          className="extraContentAutocomplete"
          onChange={handleChangeSearch}
        />
      </span>
    );
  };

  return (
    <div className="DBTab">
      <Tabs
        key={pathName}
        onChange={handleChangeTab}
        tabBarExtraContent={renderExtraContent()}
        items={tabItems}
      />
    </div>
  );
};

export default DBTab;

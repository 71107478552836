import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { setCurrentUser } from '../../actions/authActions';
import {
  auth0BaseUrl,
  auth0ClientId,
  frontendUrl,
} from '../../api/config/config';
import { AuthContextProvider, IAccessToken, ICredentials } from './auth.types';
import { useAppDispatch } from '../../hooks/reactRedux';
declare var pendo: any;

export const Auth0AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<ICredentials>();
  const [loading, setLoading] = useState(true);

  const redirectUri =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:3000'
      : frontendUrl;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const webAuth = new auth0.WebAuth({
    redirectUri: redirectUri,
    clientID: auth0ClientId as string,
    domain: new URL(auth0BaseUrl as string).host, // This needs a bare url host (no https, no trailing '/')
    audience: 'https://api.opendrawbridge.io/v2/',
    scope: 'openid profile email',
    responseType: 'id_token token',
  });

  const checkUserInfoAndStartPendo = (token: any) => {
    webAuth.client.userInfo(token, async function (err, userRes) {
      // Now you have the user's information
      if (!err && token) {
        const accessToken = token;
        // const decodedId = jwtDecode<IIdToken>(jwtToken)
        const decodedAccess = jwtDecode<IAccessToken>(accessToken);
        const { companyId, companyName, userEmail, userId, userRole } =
          decodedAccess['https://api.opendrawbridge.io/v2/'];

        // If a user is logging into the web app as participant return an error
        if (userRole !== 'manager') {
          err = { error: 'user is not manager' };
          localStorage.setItem('@authentication_error', '1');
          logout();
          return;
        }
        pendo.initialize({
          visitor: {
            id: userId, // Required if user is logged in
          },

          account: {
            id: companyId, // Required if using Pendo Feedback
          },
        });
        //}

        setUser({
          sub: userRes.sub,
          token: accessToken,
          name: userRes.name,
          email: userRes.email ?? 'n/a',
          userRole,
          companyId,
          companyName,
          userEmail,
          userId,
        });
        dispatch(setCurrentUser(userId));

        localStorage.setItem('@drawbridge_apiBearer', token);

        setLoading(false);
      }
      if (err) console.log(err);
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function authResultFn(
    err: auth0.Auth0ParseHashError | null,
    authResult: auth0.Auth0DecodedHash | null,
  ) {
    if (err) {
      setLoading(false);
      return console.log(err);
    }
    if (!authResult?.accessToken) {
      setLoading(false);
      return console.error('could not find access token');
    }
    // asdasdasdasdasdasdasdasd
    checkUserInfoAndStartPendo(authResult.accessToken);
  }

  // Extract runJWTExpCheck into its own fn
  function runJWTExpCheck(jwt: string) {
    const decodedAccess = jwtDecode<IAccessToken>(jwt as string);
    let age = decodedAccess.exp * 1000 - new Date().getTime();
    const timeToRefresh = age < 0;
    if (timeToRefresh) {
      logout();
    } else {
      checkUserInfoAndStartPendo(jwt);
    }
  }

  useEffect(() => {
    async function getToken() {
      let res = localStorage.getItem('@drawbridge_apiBearer');
      let error = localStorage.getItem('@authentication_error');

      if (error) {
        localStorage.setItem(
          '@authentication_error',
          (Number(error) + 1).toString(),
        );
      }

      /**
       * If there's a token in storage - check the session,
       * otherwise setLoading(false) - allowing the user to log back in
       */
      if (res) {
        runJWTExpCheck(res);
      } else {
        setLoading(false);
      }

      return res;
    }
    if (window.location.hash || !getToken()) {
      webAuth.parseHash(
        { hash: window.location.hash },
        function (err, authResult) {
          authResultFn(err, authResult);
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = () =>
    webAuth.authorize({
      app: 'manager',
      nonce: 'nonce',
    } as any);

  const login = async () => {
    result();
  };

  const logout = async () => {
    // log the user out of the auth provider in production
    // auth0 logout endpoint will clear the session in web-browsers
    webAuth.logout({
      clientID: auth0ClientId,
      returnTo: redirectUri,
    });
    setUser(undefined);
    localStorage.removeItem('@drawbridge_apiBearer');
    setLoading(false);
  };

  return (
    <AuthContextProvider
      value={{
        login,
        logout,
        loading,
        user,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

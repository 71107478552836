import React from 'react';
import { ConfigProvider, Layout, Menu, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  ExportOutlined,
  QuestionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import DBButton from '../DBButton/DBButton';
import './Sidebar.less';
import { openModal } from '../../actions/uiActions';
import { ModalType } from '../../models/Modal';
import { useAuth } from '../../context/auth/authContext';
import { useAppDispatch } from '../../hooks/reactRedux';
import type { MenuProps } from 'antd';

const { Sider } = Layout;

const Sidebar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { logout, user } = useAuth();
  const pathName = useLocation().pathname.slice(1);

  type MenuItem = Required<MenuProps>['items'][number];

  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
  ): MenuItem {
    return {
      key,
      label,
    } as MenuItem;
  }
  const menuItems: MenuItem[] = [
    getItem(<Link to="/overview">Overview</Link>, 'overview'),
    getItem(<Link to="/requests">Requests</Link>, 'requests'),
    getItem(<Link to="/participants">Participants</Link>, 'participants'),
    getItem(<Link to="/groups">Groups</Link>, 'groups'),
    getItem(<Link to="/restrictions">Restrictions</Link>, 'restrictions'),
  ];

  return (
    <Sider width={240} className="Sidebar">
      <div className="DBLogo">
        <Tooltip
          placement="bottom"
          title={`Build ${process.env.REACT_APP_VERSION}`}
        >
          <img
            className="DBImage"
            src={process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'}
            alt="Drawbridge Logo"
          />
        </Tooltip>
      </div>

      <Menu theme="dark" mode="inline" selectedKeys={[`${pathName}`]} items={menuItems}>
      </Menu>

      <ConfigProvider theme={{
        components: {
          Button: {
            colorPrimaryHover: '#ffffff',
            colorPrimaryActive: '#ffffff',
          },
        }
      }}>
        <div className="buttonsContainer">
          <DBButton size="large" id="helpButton" icon={<QuestionOutlined />}>
            Help
          </DBButton>
          <DBButton
            size="large"
            icon={<UserOutlined />}
            onClick={() => dispatch(openModal(ModalType.UserSettingsModal))}
          >
            {user?.name}
          </DBButton>
          <DBButton size="large" icon={<ExportOutlined />} onClick={logout}>
            Sign out
          </DBButton>
        </div>
      </ConfigProvider>
    </Sider>
  );
};

export default Sidebar;

export const httpBaseUrl = process.env.REACT_APP_BACKEND_URL;

export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

export const auth0BaseUrl = process.env.REACT_APP_AUTH0_BASE_URL;

export const frontendUrl = process.env.REACT_APP_FRONTEND_URL;

export const authorizationEndpoint =
  process.env.REACT_APP_AUTH0_BASE_URL + '/authorize';

export const logoutEndpoint =
  process.env.REACT_APP_AUTH0_BASE_URL +
  '/v2/logout?clientId=' +
  process.env.REACT_APP_AUTH0_CLIENT_ID +
  '&returnTo=' +
  process.env.REACT_APP_FRONTEND_URL;

import { capitalizeEveryWord } from './Utils';
import { UserLite, TradeRequest } from './../api/backend-api/api';
import _ from 'lodash';

export const getFiltersValues = (filters: string[], data: any) => {
  // Check if user is an instance of UserLite
  const isUserLite = (user: UserLite | boolean): user is UserLite => {
    return (user as UserLite).fullName !== undefined;
  };

  const setLabel = (text: string | boolean | UserLite, filterName?: string) => {
    if (typeof text === 'string') {
      return text;
    }

    if (text === null || text === undefined) {
      return '';
    }

    if (isUserLite(text)) {
      return text.fullName;
    }

    if (text) {
      switch (filterName) {
        case 'isManager':
          return 'Manager';
        case 'isRestricted':
        case 'reviewRequired':
          return 'Yes';
        default:
          return '';
      }
    } else {
      switch (filterName) {
        case 'isManager':
          return 'Participant';
        case 'isRestricted':
        case 'reviewRequired':
          return 'No';
        default:
          return '';
      }
    }
  };

  let filterValues: any;
  for (let i = 0; i < filters.length; i++) {
    const values = _.without(
      _.uniq(data?.map((value: any) => value[filters[i]]).flat(1)),
      null,
      undefined,
    );

    filterValues = {
      ...filterValues,
      [filters[i]]: _.sortBy(
        [
          ...values.map((value: any) => ({
            text: setLabel(value, filters[i]),
            value,
          })),
        ],
        (x) => (x.text as any).toLowerCase(),
      ),
    };
  }

  return filterValues;
};

export const createNestedFilter = (filterValues: any, property: string) => {
  let sanitizedFilter: object[] = [];
  filterValues.forEach((filter: any) => {
    sanitizedFilter.push({
      text: filter.text,
      value: filter.value[property],
    });
  });

  return _.uniqBy(sanitizedFilter, 'text');
};

export const populateTransactionsIntoCSV = (tradeRequests: TradeRequest[]) => {
  const data = tradeRequests.map((trade) => {
    const newTrade = {
      ...trade,
      reviewerEmails: [
        ...trade.reviewHistory.map((review) => ` ${review.reviewerEmail}`),
      ],
      finalReviewerEmail: trade.reviewerEmail,
      finalReviewerName: trade.reviewerName,
      finalReviewerComment: trade.reviewerComment,
      finalReviewOn: trade.reviewedOn,
    };

    return _.omit(newTrade, [
      'id',
      'tradeSubmitted',
      'tradeSubmittedOn',
      'reviewHistory',
      'reviewerEmail',
      'remainingReviewers',
      'additionalReviewer',
      'reviewerName',
      'reviewerComment',
      'reviewedOn',
    ]);
  });

  const keysFromData = Object.keys(data[0] || []);

  const headers: any[] = [];
  const formatHeader = (word: string) => {
    return capitalizeEveryWord(word.split(/(?=[A-Z])/).join(' '));
  };

  for (let key of keysFromData) {
    headers.push({ label: formatHeader(key), key });
  }

  return { headers, data };
};

import { ApprovalGroup } from './../api/backend-api/api';
import { api } from '../api/api';
import { getFiltersValues } from '../utils/dataUtils';
import { GET_APPROVAL_GROUPS, APPROVAL_GROUPS_ERROR } from './types';

export const getAllApprovalGroups = () => async (dispatch: any) => {
  try {
    const res = await api.getAllApprovalGroups();
    const filterValues = getFiltersValues(['approvalManagers'], res);

    dispatch({
      type: GET_APPROVAL_GROUPS,
      payload: res,
      filterValues,
    });
  } catch (error) {
    dispatch({
      type: APPROVAL_GROUPS_ERROR,
      payload: error,
    });
  }
};

export const addApprovalGroup =
  (approvalGroup: ApprovalGroup) => async (dispatch: any) => {
    try {
      await api.addApprovalGroup(approvalGroup);
    } catch (error) {
      dispatch({
        type: APPROVAL_GROUPS_ERROR,
        payload: error,
      });
    }
  };

export const updateApprovalGroup =
  (approvalGroupId: string, approvalGroup: ApprovalGroup) =>
  async (dispatch: any) => {
    try {
      await api.updateApprovalGroup(approvalGroupId, approvalGroup);
    } catch (error) {
      dispatch({
        type: APPROVAL_GROUPS_ERROR,
        payload: error,
      });
    }
  };

export const deleteApprovalGroup =
  (approvalGroupId: string) => async (dispatch: any) => {
    try {
      await api.deleteApprovalGroup(approvalGroupId);
    } catch (error) {
      dispatch({
        type: APPROVAL_GROUPS_ERROR,
        payload: error,
      });
    }
  };

import React from 'react';
import { Avatar, AvatarProps } from 'antd';
import { getInitials } from '../../utils/Utils';

import './DBAvatar.less';

interface DBAvatarProps {
  name: string | undefined;
  size?: AvatarProps['size'];
}

const DBAvatar: React.FC<DBAvatarProps> = ({
  name,
  size = 'default',
  ...rest
}) => {
  return (
    <Avatar className="DBAvatar" size={size} {...rest}>
      {getInitials(name)}
    </Avatar>
  );
};

export default DBAvatar;

import React from 'react';
import { Layout } from 'antd';
import { differenceInDays, format } from 'date-fns';
import './DBMainDashboard.less';
import DBDashboard from './DBDashboard';
import { Navigate, useLocation } from 'react-router-dom';
import { GeneralState } from '../../models/State';
import { useSelector } from 'react-redux';

const Dashboard: React.FC = () => {
  const pathName = useLocation().pathname;
  const { currentUser } = useSelector<GeneralState, any>((state) => state.auth);

  if (pathName === '/') {
    return <Navigate to="/overview" replace />;
  }

  return (
    <Layout className="DBDashboard">
      {currentUser && currentUser.company.trialExpiryDate ? (
        <div className="trialVersionText">
          You still have{' '}
          {Math.max(
            differenceInDays(
              new Date(currentUser.company.trialExpiryDate),
              new Date(),
            ) + 1,
            0,
          )}{' '}
          days until your trial expires on{' '}
          {format(new Date(currentUser.company.trialExpiryDate), 'dd MMM yyyy')}
        </div>
      ) : null}
      <DBDashboard />
    </Layout>
  );
};

export default Dashboard;

import React from 'react';
import DBButton from '../DBButton/DBButton';
import { Formik, Form, FormikProps } from 'formik';
import './AddGroupModal.less';
import * as Yup from 'yup';
import DBInputTextField from '../DBForm/DBInputTextField';
import { api } from '../../api/api';
import { Group } from '../../api/backend-api';

import { addGroup, getAllGroups } from '../../actions/groupActions';
import { closeModal } from '../../actions/uiActions';
import DBModalHeader from './DBModalHeader';
import { useAppDispatch } from '../../hooks/reactRedux';

interface Values {
  groupName: string;
}

const checkGroupNameExist = async (name: string) => {
  try {
    const res: Group[] | any = await api.getGroupByName(name);
    return !res.length;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const checkGroupValueHasDashes = (name: string) => {
  if (name) {
    if (name.match(/[-]/)?.length) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
};

const ValidationSchema = Yup.object().shape({
  groupName: Yup.string()
    .required('Group name cannot be blank')
    .test(
      'shouldNotBeLongerThan20Characters',
      'Name should not be longer than 20 characters',
      (value) => value!?.length <= 20,
    )
    .test(
      'shouldNotContainsDash',
      'Cannot use dash separator for group name',
      (value) => checkGroupValueHasDashes(value as string),
    )
    .test('groupAlreadyExist', 'Name is already in use', (value) => {
      return checkGroupNameExist(value as string);
    }),
});

const AddGroupModal: React.FC = () => {
  const dispatch = useAppDispatch();

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <DBModalHeader title="Add Group" className="AddGroupModal">
      <Formik
        validationSchema={ValidationSchema}
        initialValues={{
          groupName: '',
        }}
        onSubmit={(values, actions) => {
          dispatch(addGroup({ name: values.groupName }));
          actions.setSubmitting(false);
          dispatch(closeModal());
          setTimeout(() => dispatch(getAllGroups()), 500);
        }}
      >
        {(props: FormikProps<Values>) => {
          return (
            <Form className="ant-form ant-form-vertical">
              <DBInputTextField
                name="groupName"
                type="text"
                label="Group Name"
              />
              <div className="buttonsContainer">
                <DBButton
                  variety="create"
                  htmlType="submit"
                  disabled={!(props.dirty && props.isValid)}
                >
                  Create
                </DBButton>
                <DBButton variety="cancel" onClick={handleCancel}>
                  Cancel
                </DBButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </DBModalHeader>
  );
};

export default AddGroupModal;

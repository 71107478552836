import React, { useEffect, useState } from 'react';
import { Formik, Form, FormikProps } from 'formik';
import './EmailVerificationForm.less';
import DBInputTextField from '../DBForm/DBInputTextField';
import { api } from '../../api/api';
import { useNavigate } from "react-router-dom";
import { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, ConfigProvider } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import VerificationEmailMessage from './VerificationEmailMessage';
import moment from 'moment';

interface Values {
  email: string;
}

const checkEmailExist = async (email: string) => {
  try {
    const res = await api.isEmailAvailable(email);
    return res ?? false;
  } catch (err) {
    console.log(err);
    return true;
  }
};

const data = [
  {
    title: 'Access to all features',
  },
  {
    title: 'Login and experience Drawbridge for 14 days',
  },
  {
    title: 'Instructions sent directly to your inbox',
  },
  {
    title: 'Your account comes populated with sample data',
  },
  {
    title: 'Support available to help you make the most of your trial',
  },
];

const EmailVerificationForm: React.FC = () => {
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="EmailVerificationForm">
      <ConfigProvider theme={{
        components: {
          Button: {
            colorPrimary: "#75e6a8",
            colorPrimaryActive: "#75e6a8",
            colorPrimaryHover: '#a2f2c4',
          },
        }
      }}>
        <Button
          className="backButton"
          type="primary"
          size="large"
          shape="circle"
          icon={<LeftOutlined />}
          onClick={() => navigate('/')}
        />
      </ConfigProvider>
      <img
        className="wave"
        src={process.env.PUBLIC_URL + '/images/wave.svg'}
        alt="Wave"
      />
      <div className="FormContainer">
        {isSubmitted ? (
          <VerificationEmailMessage setIsSubmitted={setIsSubmitted} />
        ) : (
          <>
            <Formik
              validate={async (values) => {
                const errors: any = {};
                if (values.email.length <= 0) {
                  errors.email = 'You must enter an email';
                } else if (
                  // eslint-disable-next-line no-useless-escape
                  !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                    values.email,
                  )
                ) {
                  errors.email = 'Email is not valid';
                } else if (
                  // eslint-disable-next-line no-useless-escape
                  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
                    values.email,
                  ) &&
                  !(await checkEmailExist(values.email))
                ) {
                  errors.email = 'Email is already in use';
                }

                return errors;
              }}
              initialValues={{
                email: '',
              }}
              onSubmit={(values, actions) => {
                setIsSubmitted(true);
                const clientAuthCode = {
                  code: uuidv4(),
                  expiryTime: moment(Date.now()).add(59, 'm').toDate(),
                };
                api
                  .sendVerificationEmail(values.email, clientAuthCode.code)
                  .then(() => {
                    localStorage.setItem(
                      'clientAuthCode',
                      JSON.stringify(clientAuthCode),
                    );
                  })
                  .catch((err) => {
                    console.log(err);
                    localStorage.removeItem('clientAuthCode');
                    navigate('/failed');
                  });
                actions.setSubmitting(false);
              }}
            >
              {(props: FormikProps<Values>) => {
                return (
                  <Form className="ant-form ant-form-vertical">
                    <h3 className="formTitle">Try Drawbridge for free today</h3>
                    <DBInputTextField name="email" type="text" label="Email" />
                    <div className="buttonsContainer">
                      <input
                        type="submit"
                        disabled={!(props.dirty && props.isValid)}
                        value="Try Now"
                        style={{ height: 48 }}
                        className="input-btn ant-btn-lg DBButton"
                      />
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <div className="rightForm">
              <img
                className="DBImage"
                src={
                  process.env.PUBLIC_URL + '/images/drawbridge_logo_white.svg'
                }
                alt="Drawbridge Logo"
              />
              <h3 className="formTitleRight">Included in your free trial</h3>
              <div className="descriptionContainer">
                {data.map((d) => (
                  <div key={d.title} className="row">
                    <span className="icon">
                      <CheckCircleOutlined />
                    </span>
                    <span className="description">{d.title}</span>
                  </div>
                ))}
              </div>
              <div className="bottomText">
                You trial account expires in 14 days
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationForm;

import React from 'react';
import { Select, SelectProps } from 'antd';
import './DBSelect.less';

interface Option {
  key: string | number;
  label: string;
  value: string | number;
}

interface DBSelectProps extends SelectProps<any> {
  options: Option[];
  theme?: 'night' | 'light';
  collapse?: boolean;
}

const DBSelect: React.FC<DBSelectProps> = ({
  options,
  value,
  theme = 'night',
  collapse = false,
  ...rest
}) => {
  const renderOption = (option: Option) => (
    <Select.Option key={option.key} value={option.value} label={option.label}>
      {option.label}
    </Select.Option>
  );

  return (
    <Select
      className={theme === 'light' ? 'DBSelectLight select' : 'select'}
      value={value}
      maxTagCount={collapse ? 'responsive' : 1000}
      getPopupContainer={() => {
        return document.getElementsByClassName('select')[0] as HTMLElement;
      }}
      {...rest}
    >
      {options.map(renderOption)}
    </Select>
  );
};

export default DBSelect;

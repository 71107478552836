import React, { useMemo } from 'react';
import { useBoolean } from '../../utils/useBoolean';

export interface BoxProps extends React.CSSProperties {
  children?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /** utility psudo class to access hover styles */
  _hover?: React.CSSProperties;
}

/**
 * Box composes View and allows you to pass StyleProps,
 * directly to the View
 * Shorthand approach to creating a Box ,
 * and adds animation and hoverStyles by default
 */
const Box = ({ children, onClick, _hover, ...style }: BoxProps) => {
  // Pass that ref to the hooks...
  const [isHovered, hoveredToggler] = useBoolean(false);

  const hoverActiveStyles = useMemo(
    () => (_hover && isHovered ? _hover : {}),
    [isHovered, _hover],
  );

  return (
    <div
      onMouseOver={() => hoveredToggler.on()}
      onMouseOut={() => hoveredToggler.off()}
      onClick={onClick}
      style={{
        display: 'block',
        ...style,
        ...hoverActiveStyles,
      }}
      children={children}
    />
  );
};

export default Box;

import { getFiltersValues } from './../utils/dataUtils';
import { TradeRequest } from './../api/backend-api/api';
import { api } from '../api/api';
import { GET_TRADE_REQUESTS, TRADE_REQUESTS_ERROR } from './types';

export const getAllTradeRequests =
  (
    email?: string | undefined,
    status?: 'PENDING' | 'APPROVED' | 'REJECTED' | 'CANCELLED' | undefined,
  ) =>
  async (dispatch: any) => {
    try {
      const res = await api.getAllTradeRequests(email, status);
      const filterValues = getFiltersValues(['status', 'tradeDirection'], res);

      dispatch({
        type: GET_TRADE_REQUESTS,
        payload: res,
        filterValues,
      });
    } catch (error) {
      dispatch({
        type: TRADE_REQUESTS_ERROR,
        payload: error,
      });
    }
  };

export const updateTradeRequest =
  (tradeRequestId: string, tradeRequest: TradeRequest) =>
  async (dispatch: any) => {
    try {
      await api.updateTradeRequest(tradeRequestId, tradeRequest);
    } catch (error) {
      dispatch({
        type: TRADE_REQUESTS_ERROR,
        payload: error,
      });
    }
  };

import React, { PropsWithChildren, useEffect } from 'react';
import ReactDataSheet from 'react-datasheet';
import 'react-datasheet/lib/react-datasheet.css';
import { setModalWidth } from '../../../actions/uiActions';
import { CSVProvider, useImportAPI } from '../../../context/CSVContext';
import Box from '../../Box/Box';
import Flex from '../../Flex/Flex';
import DBModalHeader from '../DBModalHeader';
import './AddParticipantBulkModal.less';
import CSVUpload from './CSVUpload';
import TableImport from './TableImport';
import { useAppDispatch } from '../../../hooks/reactRedux';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
  value: number | string | null;
}

const AddParticipantBulkModal: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { tabIndex, setTabIndex } = useImportAPI();

  useEffect(() => {
    const setWidthdefaultWidth = () => {
      dispatch(setModalWidth(undefined));
    };
    dispatch(setModalWidth(500));

    return () => setWidthdefaultWidth();
  }, [dispatch]);

  return (
    <DBModalHeader
      title="Bulk Add Participants"
      className="AddParticipantModal"
    >
      <Flex width="100%">
        {
          /**
           * This is native JS way to make a tabbed component with a custom UI,
           * It is a JSX Element array that is filtered based on tabIndex state
           */
          [
            // TAB 1
            <Box width="100%">
              <Flex justifyContent="space-evenly" width="100%" margin="20px 0">
                {/* Commenting this out for now until CSV Upload is tested and live on dev */}
                <button className="tab" onClick={(e) => setTabIndex(1)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                    <g fill="currentColor">
                      <path
                        data-color="color-2"
                        d="M2 9a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6a1 1 0 1 1 0 2H3v5a1 1 0 0 1-1 1zM8 48H2a1 1 0 0 1-1-1v-6a1 1 0 1 1 2 0v5h5a1 1 0 1 1 0 2zM47 9a1 1 0 0 1-1-1V3h-5a1 1 0 1 1 0-2h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zM21 3h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zM2 22a1 1 0 0 1-1-1v-6a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1zM2 35a1 1 0 0 1-1-1v-6a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1zM47 22a1 1 0 0 1-1-1v-6a1 1 0 1 1 2 0v6a1 1 0 0 1-1 1zM47 31a1 1 0 0 1-1-1v-2a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1zM34 3h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zM21 48h-6a1 1 0 1 1 0-2h6a1 1 0 1 1 0 2zM30 48h-2a1 1 0 1 1 0-2h2a1 1 0 1 1 0 2z"
                      />
                      <path d="M62.707 57.293 51.688 46.274l10.759-5.379a1 1 0 0 0-.168-1.855l-31-9a1 1 0 0 0-1.239 1.239l9 31A.998.998 0 0 0 40 63c.376 0 .724-.212.895-.553l5.38-10.759 11.019 11.019a.999.999 0 0 0 1.414 0l4-4a1 1 0 0 0-.001-1.414z" />
                    </g>
                  </svg>
                  Copy-paste
                </button>
                <button className="tab" onClick={(e) => setTabIndex(2)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                    <g fill="currentColor">
                      <path d="M17 4h-7a5.937 5.937 0 0 0-6 6v7a1 1 0 0 0 2 0v-7a3.957 3.957 0 0 1 4-4h7a1 1 0 0 0 0-2ZM54 4h-7a1 1 0 0 0 0 2h7a3.957 3.957 0 0 1 4 4v7a1 1 0 0 0 2 0v-7a5.937 5.937 0 0 0-6-6ZM59 46a1 1 0 0 0-1 1v7a3.957 3.957 0 0 1-4 4h-7a1 1 0 0 0 0 2h7a5.937 5.937 0 0 0 6-6v-7a1 1 0 0 0-1-1ZM17 58h-7a3.957 3.957 0 0 1-4-4v-7a1 1 0 0 0-2 0v7a5.937 5.937 0 0 0 6 6h7a1 1 0 0 0 0-2ZM25 6h14a1 1 0 0 0 0-2H25a1 1 0 0 0 0 2ZM39 58H25a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2ZM59 24a1 1 0 0 0-1 1v14a1 1 0 0 0 2 0V25a1 1 0 0 0-1-1ZM5 40a1 1 0 0 0 1-1V25a1 1 0 0 0-2 0v14a1 1 0 0 0 1 1Z" />
                      <path
                        data-color="color-2"
                        d="M32.781 15.375a1.036 1.036 0 0 0-1.562 0l-12 15A1 1 0 0 0 20 32h9v14a3 3 0 0 0 6 0V32h9a1 1 0 0 0 .781-1.625Z"
                      />
                    </g>
                  </svg>
                  CSV Upload
                </button>
              </Flex>
              <div
                style={{
                  color: '#323f4b',
                  marginTop: '12px',
                  marginBottom: 0,
                  fontSize: 18,
                }}
              >
                Bulk Upload Tasks
              </div>
              {children}
            </Box>,
            // TAB 2
            <TableImport />,
            // TAB 3
            <CSVUpload />,
          ].find((tab, index) => index === tabIndex && tab)
        }
      </Flex>
    </DBModalHeader>
  );
};

/**
 * This is a shorthand way of wrapping the default component in the necessary context library
 */
const WrappedWithProvider = (props: any) => (
  <CSVProvider>
    <AddParticipantBulkModal {...props} />
  </CSVProvider>
);

export default WrappedWithProvider;

import { App, ConfigProvider } from "antd";
import React, { ReactNode } from "react";
interface AntConfigProviderProps {
  children: ReactNode;
}

const AntConfigProvider: React.FC<AntConfigProviderProps> = ({ children }) => {
  return (
    <ConfigProvider theme={{
      token: {
        colorPrimary: "#75e6a8",
        colorInfo: "#75e6a8",
        colorInfoHover: "#a2f2c4",
        colorBgContainer: "#262d46",
        borderRadius: 2,
        colorBorderSecondary: '#685562',
        colorTextHeading: '#aea4ab',
      },
      components: {
        Table: {
          colorIcon: '#ffffff',
          colorIconHover: '#ffffff',
          fontWeightStrong: 500,
        },
        Button: {
          colorPrimary: '#000000',
          colorPrimaryHover: '#000000',
          colorPrimaryActive: '#000000',
        },
      }
    }}>
      <App>
        {children}
      </App>
    </ConfigProvider>
  );
};

export default AntConfigProvider;

import React from 'react';
import { useSelector } from 'react-redux';
import { GeneralState } from '../../models/State';
import { formatDate } from '../../utils/Utils';
import DBTag from '../DBTag/DBTag';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';

const ViewRestrictionModal: React.FC = () => {
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);

  const renderGroupTags = (groups: string[]) => {
    return currentRow.groups.length !== 0
      ? groups.map((group) => (
          <DBTag key={group} theme="light" isInModal={true}>
            {group}
          </DBTag>
        ))
      : '';
  };

  return (
    <DBModalHeader title="View Restriction" className="ViewRestrictionModal">
      <ModalTextField title="Name" description={currentRow.name} />
      <ModalTextField
        title="Groups"
        description={renderGroupTags(currentRow.groups)}
      />
      <ModalTextField
        title="Duration"
        description={`${formatDate(
          currentRow.startDate,
          'd MMM yyyy',
        )} - ${formatDate(currentRow.endDate, 'd MMM yyyy')}`}
      />
      <ModalTextField
        title="Description"
        description={currentRow.description ? currentRow.description : ''}
      />
    </DBModalHeader>
  );
};

export default ViewRestrictionModal;

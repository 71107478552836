import { ActiveTradesRequestState } from '../models/State';
import {
  ACTIVE_TRADES_ERROR,
  ACTIVE_TRADES_PER_MONTH_ERROR,
  GET_ACTIVE_TRADES,
  GET_ACTIVE_TRADES_PER_MONTH,
} from './../actions/types';

const initialState: ActiveTradesRequestState = {
  activeTrades: [],
  activeTradesPerMonth: [],
  loading: true,
  error: undefined,
};

export const activeTradesReducer = (
  state: ActiveTradesRequestState = initialState,
  action: any,
) => {
  switch (action.type) {
    case GET_ACTIVE_TRADES:
      return {
        ...state,
        activeTrades: action.payload,
        loading: false,
      };
    case ACTIVE_TRADES_ERROR:
      return {
        activeTrades: [],
        activeTradesPerMonth: [],
        loading: false,
        error: action.payload,
      };
    case GET_ACTIVE_TRADES_PER_MONTH:
      return {
        ...state,
        activeTradesPerMonth: action.payload,
        loading: false,
      };
    case ACTIVE_TRADES_PER_MONTH_ERROR:
      return {
        activeTrades: [],
        activeTradesPerMonth: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

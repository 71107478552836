import React from 'react';
import { Table } from 'antd';
import DBTableText from '../DBTableText/DBTableText';
import { TradeRequest, TradeRequestStatusEnum } from '../../api/backend-api';
import { compareTradeRequests, formatDate } from '../../utils/Utils';
import { Link } from 'react-router-dom';

import './ActiveApprovalsTable.less';

const columns = [
  {
    key: 'requesterName',
    title: 'Name',
    dataIndex: 'requesterName',
    ellipsis: true,
    render: (text: string, record: TradeRequest) => (
      <DBTableText mainText={text} />
    ),
  },
  {
    key: 'details',
    title: 'Details',
    dataIndex: 'createdOn',
    ellipsis: true,
    render: (text: string, record: TradeRequest) => (
      <DBTableText
        mainText={`${formatDate(record.startDate, 'd MMM yyyy')} - ${formatDate(
          record.endDate,
          'd MMM yyyy',
        )}`}
      />
    ),
  },
  {
    key: 'tradeDirection',
    title: 'Trade',
    dataIndex: 'tradeDirection',
    render: (trade: string) =>
      trade === 'BUY' ? (
        <span className="trade-buy">{trade.toLowerCase()}</span>
      ) : (
        <span className="trade-sell">{trade.toLowerCase()}</span>
      ),
  },
  {
    key: 'amount',
    title: 'Amount',
    dataIndex: 'actions',
    className: 'ActiveApprovals',
    render: (text: string, record: TradeRequest) => (
      <DBTableText
        mainText={`${record.assetQuantity.toLocaleString()} ${record.assetType?.toLowerCase()}s`}
      />
    ),
  },
];

interface ActiveApprovalsTableProps {
  tradeRequests: TradeRequest[];
  loading: boolean;
}

const ActiveApprovalsTable: React.FC<ActiveApprovalsTableProps> = ({
  tradeRequests,
  loading,
}) => {
  const rowToShow = (tradeRequests: TradeRequest[]) => {
    return [...tradeRequests]
      .sort(compareTradeRequests)
      .filter(
        (req) =>
          req.status === TradeRequestStatusEnum.Approved ||
          req.status === TradeRequestStatusEnum.AutoApproved,
      )
      .slice(0, 5);
  };

  return (
    <div className="ActiveApprovalContainer">
      <div className="ActiveApprovalsHeader">
        <h3 className="title">Active Approvals</h3>
        <Link to="/requests">View all</Link>
      </div>
      <Table
        className="ActiveApprovalsTable"
        size="large"
        rowKey="id"
        scroll={{ x: 'max-content' }}
        pagination={false}
        loading={loading}
        columns={columns as any}
        dataSource={rowToShow(tradeRequests)}
      />
    </div>
  );
};

export default ActiveApprovalsTable;

import React from 'react';
import DBButton from '../DBButton/DBButton';
import './DBNotFound.less';

const DBNotFound: React.FC = () => {
  return (
    <div className="DBNotFound">
      <h2 className="title">404</h2>
      <h3 className="description">
        Sorry, the page you were looking for doesn't exist.
      </h3>
      <DBButton
        onClick={() => window.location.replace('/')}
        style={{ maxWidth: 120 }}
        variety="create"
      >
        Go Back
      </DBButton>
    </div>
  );
};

export default DBNotFound;

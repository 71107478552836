import { format } from 'date-fns';
import React from 'react';
import { User } from '../../api/backend-api';
import DBAvatar from '../DBAvatar/DBAvatar';

import './UserHeader.less';

interface UserHeaderProps {
  user: User | undefined;
}

const UserHeader: React.FC<UserHeaderProps> = ({ user }) => {
  const userName = `${user?.firstName} ${user?.lastName}`;

  const renderTrialText = (date: string) => (
    <p className="trialText">
      Your trial account expires on {format(new Date(date), 'dd MMMM yyyy')}
    </p>
  );

  return (
    <div className="UserHeader">
      <DBAvatar name={userName} size={60} />
      <div className="UserHeaderContent">
        <div className="name">{`${userName}`}</div>
        {user && user?.company?.trialExpiryDate
          ? renderTrialText(user?.company?.trialExpiryDate)
          : null}
      </div>
    </div>
  );
};

export default UserHeader;

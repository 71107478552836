import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllGroups, updateGroup } from '../../actions/groupActions';
import { closeModal } from '../../actions/uiActions';
import { Group } from '../../api/backend-api';
import { GeneralState } from '../../models/State';
import DBButton from '../DBButton/DBButton';
import DBSwitchField from '../DBForm/DBSwitchField';
import DBModalHeader from './DBModalHeader';
import ModalTextField from './ModalTextField';
import './ApprovalSettingsModal.less';
import { useAppDispatch } from '../../hooks/reactRedux';

const ApprovalSettingsModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const { currentRow } = useSelector<GeneralState, any>((state) => state.ui);
  const [group, setNewGroup] = useState<Group>(currentRow);

  const handleReviewRequired = (value: boolean) => {
    setNewGroup({ ...currentRow, reviewRequired: value });
  };

  const handleSubmit = () => {
    dispatch(updateGroup(currentRow.id, group));
    dispatch(closeModal());
    setTimeout(() => dispatch(getAllGroups()), 500);
  };

  const handleCancel = () => {
    dispatch(closeModal());
  };

  return (
    <DBModalHeader title="Approval Settings" className="ApprovalSettingsModal">
      <ModalTextField description="Turn on to enable manual approval for this group." />
      <ModalTextField description="All trades requests submitted by participants in this group will be pending until either approved or declined by a manager" />
      <DBSwitchField
        defaultChecked={currentRow.reviewRequired}
        onChange={handleReviewRequired}
      />
      <div className="buttonsContainer">
        <DBButton variety="create" htmlType="submit" onClick={handleSubmit}>
          Done
        </DBButton>
        <DBButton variety="cancel" onClick={handleCancel}>
          Cancel
        </DBButton>
      </div>
    </DBModalHeader>
  );
};

export default ApprovalSettingsModal;
